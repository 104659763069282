import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

*,*::before,*::after{
    box-sizing:border-box;
-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
    font-size: 62.5%; 
}

*{
  font-family: 'Josefin Sans', sans-serif !important; 
}

  body{
    font-size:1.6rem;
    font-family: 'Josefin Sans', sans-serif !important; 
    min-height:calc(100vh -11rem);
    margin:0;
}



`;

export default GlobalStyle;
