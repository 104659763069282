import React, { useEffect } from "react";

import "./Zoom.scss";
const Zoom = (props) => {
  const { width, height, updateDimensions } = props;

  const minWidth = 1280;
  const maxWidth = 3900;

  useEffect(() => {
    let sc = document.getElementsByClassName("scroll-container")[0];

    let ww = window.innerWidth;
    let wh = window.innerHeight;

    sc.scrollTo({
      left: (width - ww) / 2,
      top: (height - wh) / 2,
    });
  });

  return (
    <>
      {/* ========================PLUS================================ */}
      <button
        className="plus"
        onClick={() => {
          let ratio = width > maxWidth ? 1 : 1.25;

          updateDimensions(Math.round(width * ratio), Math.round(height * ratio));

          let sc = document.getElementsByClassName("scroll-container")[0];

          sc.scrollTo({
            left: sc.scrollLeft * ratio, // (nw-ww)/2,
            top: sc.scrollTop * ratio, //(nh-wh)/2,
          });
        }}
      >
        +
      </button>
      {/* ========================MINUS================================ */}
      <button
        className="minus"
        onClick={() => {
          let ratio = width > minWidth ? 0.8 : 1;

          updateDimensions(Math.round(width * ratio), Math.round(height * ratio));

          let sc = document.getElementsByClassName("scroll-container")[0];

          sc.scrollTo({
            left: sc.scrollLeft * ratio, // (nw-ww)/2,
            top: sc.scrollTop * ratio, //(nh-wh)/2,
          });
        }}
      >
        -
      </button>
    </>
  );
};

export default Zoom;
