import React, { useContext, useEffect, useState } from "react";
import rok from "assets/s4/z16/rok.svg";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Context } from "context";
import Sound from "react-sound";

import { zaktualizujElementy as zaktualizujElementyAction } from "actions/gameActions";

import { zaktualizujRozwiazanie as zaktualizujRozwiazanieAction } from "actions/gameActions";

import { useDrag } from "react-dnd";

import update from "immutability-helper";

import { useDrop } from "react-dnd";

import speaker from "assets/s4/z16/speaker.svg";

export const Box = ({ id, left, top, position, children }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: "ItemTypes.BOX" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <div className="cards" ref={drag} style={{ left, top, position }}>
      {children}
    </div>
  );
};

const Z16 = ({ elementy, zaktualizujElementy, zaktualizujRozwiazanie }) => {
  const history = useHistory();

  const [pos, setPos] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [s1, sets1] = useState([])

  const { canGo, setCanGo } = useContext(Context);

  useEffect(() => {
    setCanGo(false);
  }, []);

  useEffect(() => {
    // const first = elementy[0]
    let count = 0;
    elementy.map((first, index) => {
      const x1 = first.left + 0.5 * first.width;
      const y1 = first.top + 0.5 * first.height;

      const x2 = first.posLeft + 0.5 * first.width;
      const y2 = first.posTop + 0.5 * first.height;

      if (Math.sqrt(Math.pow(y2 - y1, 2) + Math.pow(x2 - x1, 2)) < 80) {
        count += 1;
        setPos(count);
        first.left = first.posLeft;
        first.top = first.posTop;
       

        if(!s1.includes(first.id)) {
          sets1(oldArray => [...oldArray, first.id])
        } 
      } else {
        setPos(count);
        if(s1.includes(first.id)) {
          sets1(s1.filter(item => item !== first.id))
        }
      }

      // console.log(y2-y1)
      // console.log(x2-x1)
      // console.log(Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)))
    });

    if (pos === 12) {
      setCanGo(true);
    } else {
      setCanGo(false);
    }
  }, [elementy, pos, s1, setCanGo]);



  const [collectedProps, drop] = useDrop({
    accept: "ItemTypes.BOX",
    drop(item, monitor) {
      const start_pos = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      // console.log(start_pos, delta);
      const left = item.left
        ? Math.round(item.left + delta.x)
        : start_pos.x + delta.x - 150;
      const top = item.top
        ? Math.round(item.top + delta.y)
        : start_pos.y + delta.y - 250;
      // const left = 500
      // const top = -200
      moveBox(item.id, left, top);
      return undefined;
    },
  });

  const moveBox = (id, left, top) => {
    var el = update(elementy, {
      [id]: {
        $merge: { left, top, position: "absolute" },
      },
    });
    // console.log(el);

    zaktualizujRozwiazanie(el, 16);
  };
  // console.log(isPlaying);

  return (
    <div className="z z16" ref={drop}>
      <img className="drop drop1" src={rok} alt="" />

      <div className="months">
        {Object.keys(elementy).map((key) => {
          const { left, top, position, image, title, klasa, sound, id } = elementy[key];
          // console.log(key);
          return (
            <div key={`${id}as`}>
            <Box key={key} id={key} left={left} top={top}>
              <p className={`${klasa} miesiac`} onClick={() => setIsPlaying(id)}>{title}</p>
         
            </Box>
              <Sound
              playStatus={isPlaying === id && s1.includes(id)  ? Sound.status.PLAYING : Sound.status.STOPPED}
              url={sound}
              volume={80}
              onFinishedPlaying={() => setIsPlaying(false)}
            />
            </div>
          );
          
        })}

      

        {/* <div className="drag m2">Luty</div>
        <div className="drag m3">Marzec</div>
        <div className="drag m4">Kwiecień</div>
        <div className="drag m5">Maj</div>
        <div className="drag m6">Czerwiec</div>
        <div className="drag m7">Lipiec</div>
        <div className="drag m8">Sierpień</div>
        <div className="drag m9">Wrzesień</div>
        <div className="drag m10">Październik</div>
        <div className="drag m11">Listopad</div>
        <div className="drag m12">Grudzień</div> */}
      </div>
    </div>
  );
};

Z16.propTypes = {};

const mapStateToProps = (state) => {
  // console.log(state.gameReducer);
  return {
    elementy: state.gameReducer.rozwiazane_zadania[15].elementy,
    rozwiazane_zadanie: state.gameReducer.rozwiazane_zadania[15],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaktualizujRozwiazanie: (elementy, zadanieID) =>
      dispatch(zaktualizujRozwiazanieAction(elementy, zadanieID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Z16);
