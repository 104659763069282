import React, {useContext, useEffect} from "react";
import { Context } from "context";
import taniec from "assets/s5/z23/taniec.svg";

const Z23 = () => {

  const {
    canGo, setCanGo
  } = useContext(Context);

  


  useEffect(() => {
    setCanGo(true)
  }, [])


  return (
    <div className="z23">
      <img src={taniec} alt="taniec" />
    </div>
  );
};

export default Z23;
