//import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
 import { useHistory } from "react-router-dom";
 import { Context } from "context";

import { zaktualizujRozwiazanie as zaktualizujRozwiazanieAction } from "actions/gameActions";

import cien from "assets/s1/z1/cien2.svg";

import { useDrag } from "react-dnd";

import update from "immutability-helper";

import { useDrop } from "react-dnd";



export const Box = ({ id, left, top, position, children, klasa , }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: "ItemTypes.BOX" },
    
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    
  });
  if (isDragging) {
    return <div className={klasa}  ref={drag} style={{cursor: "grab"}} />;
  }

  return (
    <div className={klasa} ref={drag} style={{ left, top, position, zIndex: 3,  }}>
      {children}
    </div>
  );
};




const Z1 = ({ rozwiazane_zadanie, elementy, zaktualizujRozwiazanie }) => {

  const {
    canGo, setCanGo
  } = useContext(Context);

  const [pos, setPos] = useState()

// useEffect(() => {
//   if(JSON.stringify(elementy.map(el => el.posLeft)) === JSON.stringify(elementy.map(el => el.left))){
//     setPos("YEA")
//   }else {
//     setPos("no")
//   }
// }, [elementy])

//   console.log(elementy.map(el => el.posLeft))
//   console.log(elementy.map(el => el.left))
//   console.log(JSON.stringify(elementy.map(el => el.posLeft)))

// console.log(pos)

useEffect(() => {
  // const first = elementy[0]
  let count = 0;
  elementy.map( (first, index) => {
  const x1 = first.left + 0.5 * first.width
  const y1 = first.top + 0.5 * first.height

  const x2 = first.posLeft + 0.5 * first.width
  const y2 = first.posTop + 0.5 * first.height

  if (Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)) < 80){
    count += 1;
    setPos(count) 
    first.left = first.posLeft
    first.top = first.posTop
  }else {
    setPos(count)
  }


  // console.log(y2-y1)
  // console.log(x2-x1)
// console.log(Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)))
})

if(pos === 7) {
  setCanGo(true)
}else {
  setCanGo(false)
}

}, [elementy, pos, setCanGo])

// console.log(pos)


  const [whichElement, setWhichElement] = useState()
  // useEffect(() => {
  //   console.log(elementy[0].width)
  // })
   // eslint-disable-next-line no-unused-vars
   const history = useHistory();
  // console.log(elementy);

  // eslint-disable-next-line no-unused-vars
  const [collectedProps, drop] = useDrop({
    accept: "ItemTypes.BOX",
    drop(item, monitor, collectedProps) {
      const start_pos = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      // console.log(start_pos, delta);
      const left = item.left ? Math.round(item.left + delta.x) : start_pos.x + delta.x - 175;
      const top = item.top ? Math.round(item.top + delta.y) : start_pos.y + delta.y - 248;
      setWhichElement(item.id)
      moveBox(item.id, left, top);

      return undefined;
    },
  });
  const moveBox = (id, left, top) => {
    // console.log(left, top)
    var el = update(elementy, {
      [id]: {
        $merge: { left, top },
      },
    });
    zaktualizujRozwiazanie(el, 1);
    
    
  };




  // console.log(whichElement)
  //  console.log(elementy[whichElement].height, elementy[whichElement].width)

  return (
    <div className="z z1"  ref={drop}>
      <div className="drop">
        <img src={cien} alt="cień" />
      </div>
      <div className="drag">
        {Object.keys(elementy).map((key) => {
          const { left, top, position, image, klasa } = elementy[key];
          // console.log(key);
          
          return (
            
            <Box key={key} id={key} left={left} top={top} position={position}>
              <img  className={klasa} src={image} alt=""  />
            </Box>
          );
        })}
      </div>
      {/* 
      <h1 className="pasieka__title">Dokończ budowę swojej pasieki</h1>
      <div className="pasieka__budowa" ref={drop}>
        <img src={pasieka} alt="pasieka" />

        <div className="elementy">
          <div className="elementy__lista">
            {Object.keys(elementy).map((key) => {
              const { left, top, position, image } = elementy[key];
              console.log(key);
              return (
                <Box key={key} id={key} left={left} top={top} position={position}>
                  <img src={image} alt="" />
                </Box>
              );
            })}
          </div>
        </div>
      </div> */}
    </div>
  );
};

Z1.propTypes = {};

const mapStateToProps = (state) => {
  // console.log(state.gameReducer);
  return {
    elementy: state.gameReducer.rozwiazane_zadania[0].elementy,
    rozwiazane_zadanie: state.gameReducer.rozwiazane_zadania[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaktualizujRozwiazanie: (elementy, zadanieID) => dispatch(zaktualizujRozwiazanieAction(elementy, zadanieID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Z1);
