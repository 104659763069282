import React, { useState, useContext, useEffect } from "react";

import oj from "assets/s3/z12/oj.svg";
import wy_pszczolki from "assets/s3/z12/wy_pszczolki.svg";
import stare from "assets/s3/z12/stare.svg";
import oj_miodku from "assets/s3/z12/oj_miodku.svg";
import kochany from "assets/s3/z12/kochany.svg";
import przez_was from "assets/s3/z12/przez_was.svg";
import mamy from "assets/s3/z12/mamy.svg";
import wiare from "assets/s3/z12/wiare.svg";
import piast_obrany from "assets/s3/z12/piast_obrany.svg";
import przez from "assets/s3/z12/przez.svg";
import { Context } from "context";

import one from "assets/s3/z12/1.svg";
import two from "assets/s3/z12/2.svg";
import three from "assets/s3/z12/3.svg";
import four from "assets/s3/z12/4.svg";
import five from "assets/s3/z12/5.svg";
import six from "assets/s3/z12/6.svg";
import seven from "assets/s3/z12/7.svg";
import eight from "assets/s3/z12/8.svg";
import nine from "assets/s3/z12/9.svg";
import ten from "assets/s3/z12/10.svg";

const Z12 = () => {
  const [in1, setIn1] = useState();
  const [in2, setIn2] = useState();
  const [in3, setIn3] = useState();
  const [in4, setIn4] = useState();
  const [in5, setIn5] = useState();
  const [in6, setIn6] = useState();
  const [in7, setIn7] = useState();
  const [in8, setIn8] = useState();
  const [in9, setIn9] = useState();
  const [in10, setIn10] = useState();

  const { canGo, setCanGo } = useContext(Context);

  const [pos, setPos] = useState();

  useEffect(() => {
    setCanGo(false);
  }, []);

  useEffect(() => {
    if(in1  && in2 && in3 && in4 && in5 && in6 && in7 && in8 && in9 && in10 !== undefined) {
      if (
        in1.replace(/\s+/g, '').toLowerCase() === "oj" &&
        in2.replace(/\s+/g, '').toLowerCase() === "wypszczółki" &&
        in3.replace(/\s+/g, '').toLowerCase() === "stare" &&
        in4.replace(/\s+/g, '').toLowerCase() === "ojmiodku" &&
        in5.replace(/\s+/g, '').toLowerCase() === "kochany" &&
        in6.replace(/\s+/g, '').toLowerCase() === "przezwas" &&
        in7.replace(/\s+/g, '').toLowerCase() === "mamy" &&
        in8.replace(/\s+/g, '').toLowerCase() === "wiarę" &&
        in9.replace(/\s+/g, '').toLowerCase() === "przezwas" &&
        in10.replace(/\s+/g, '').toLowerCase() === "piastobrany"
      ) {
        setCanGo(true);
      }else {
        setCanGo(false)
      }
    }
    

  }, [in1, in10, in2, in3, in4, in5, in6, in7, in8, in9, setCanGo]);

  return (
    <div className="z z12">
      <div className="piosenka">
        <div className="znaki-row">
          <div className="znaki">
            <img src={oj} alt="" />
            <input
              type="text"
              id="01"
              name="01"
              className="css-input12"
              autoComplete="off"
              onChange={(e) => setIn1(e.target.value)}
            />
          </div>
          <div className="znaki">
            <img src={wy_pszczolki} alt="" />
            <input
              type="text"
              id="02"
              name="02"
              className="css-input12"
              autoComplete="off"
              onChange={(e) => setIn2(e.target.value)}
            />
          </div>
          <div className="znaki">
            <img src={stare} alt="" />
            <input
              type="text"
              id="03"
              name="03"
              className="css-input12"
              autoComplete="off"
              onChange={(e) => setIn3(e.target.value)}
            />
          </div>
        </div>

        <div className="znaki-row">
          <div className="znaki">
            <img src={oj_miodku} alt="" />
            <input
              type="text"
              id="04"
              name="04"
              className="css-input12"
              autoComplete="off"
              onChange={(e) => setIn4(e.target.value)}
            />
          </div>
          <div className="znaki">
            <img src={kochany} alt="" />
            <input
              type="text"
              id="05"
              name="05"
              className="css-input12"
              autoComplete="off"
              onChange={(e) => setIn5(e.target.value)}
            />
          </div>
        </div>

        <div className="znaki-row">
          <div className="znaki">
            <img src={przez_was} alt="" />
            <input
              type="text"
              id="06"
              name="06"
              className="css-input12"
              autoComplete="off"
              onChange={(e) => setIn6(e.target.value)}
            />
          </div>
          <div className="znaki">
            <img src={mamy} alt="" />
            <input
              type="text"
              id="07"
              name="07"
              className="css-input12"
              autoComplete="off"
              onChange={(e) => setIn7(e.target.value)}
            />
          </div>
          <div className="znaki">
            <img src={wiare} alt="" />
            <input
              type="text"
              id="08"
              name="08"
              className="css-input12"
              autoComplete="off"
              onChange={(e) => setIn8(e.target.value)}
            />
          </div>
        </div>

        <div className="znaki-row">
          <div className="znaki">
            <img src={przez_was} alt="" />
            <input
              type="text"
              id="09"
              name="09"
              className="css-input12"
              autoComplete="off"
              onChange={(e) => setIn9(e.target.value)}
            />
          </div>
          <div className="znaki">
            <img src={piast_obrany} alt="" />
            <input
              type="text"
              id="10"
              name="10"
              className="css-input12"
              autoComplete="off"
              onChange={(e) => setIn10(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="klucz_kodowy">
        <div>
          {" "}
          <h2>Klucz Kodowy</h2>
        </div>
        <div className="znaki-klucz">
          <div className="znaki">
            <img src={one} alt="" />
            Piast obrany
          </div>
          <div className="znaki">
            <img className="iks" src={two} alt="" />
            mamy
          </div>
          <div className="znaki">
            <img src={three} alt="" />
            wy pszczółki
          </div>
          <div className="znaki">
            <img src={four} alt="" />
            oj miodku
          </div>
          <div className="znaki">
            <img className="iks" src={five} alt="" />
            przez
          </div>
          <div className="znaki">
            <img src={six} alt="" />
            wiarę
          </div>
          <div className="znaki">
            <img src={seven} alt="" />
            oj
          </div>
          <div className="znaki">
            <img src={eight} alt="" />
            stare
          </div>
          <div className="znaki">
            <img src={nine} alt="" />
            kochany
          </div>
          <div className="znaki">
            <img className="iks" src={ten} alt="" />
            przez was
          </div>
        </div>
      </div>
    </div>
  );
};

export default Z12;
