import React, { useEffect, useState, useContext } from "react";
import cx from "classnames";
import { Context } from "context";

const slowa = ["miód", "pasieka", "klapki", "jajeczko", "larwa", "propolis", "kószka", "królowa", "podkurzacz", "ul", "poczwarka", "rodzina", "rękawice", "pszczoła", "kwiat", "bartnik"];

const Z21 = () => {
const [isClicked, setIsClicked] = useState(["larwa"])


// console.log(isClicked)
// console.log(slowa.length)

const {
  canGo, setCanGo
} = useContext(Context);

//  const [pos, setPos] = useState()


useEffect(() => {
  setCanGo(false)
}, [])

const jajo = isClicked.includes("jajeczko") ||  isClicked.includes("larwa")  || isClicked.includes("poczwarka") || isClicked.includes("pszczoła")

useEffect(() => {
if((isClicked.includes("jajeczko") &&  isClicked.includes("larwa")  && isClicked.includes("poczwarka") && isClicked.includes("pszczoła")) && isClicked.length === 4){
setCanGo(true)
}else if (isClicked.length !== 4) {
setCanGo(false)
}
},[isClicked, setCanGo])

  return (
    <div className="z z21">
      {slowa.map((slowo) => {
        return (
          <div className="slowo" key={slowo}>
            <p className={cx(`${slowo} sl`, isClicked.includes(slowo) ? "active" : "")} 
            onClick={() => {
              if(!isClicked.includes(slowo)) {
                setIsClicked(oldArray => [...oldArray, slowo])
              
              } else if (isClicked.includes(slowo)) {
                setIsClicked(isClicked.filter(item => item !== slowo))
              } 
              }
            }
            
            >
              
              {slowo.toUpperCase()}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Z21;
