import React, { useContext, useEffect } from "react";
import z7data from "./z7data"
import natura from "assets/s2/z7/natura2.svg";
import { Context } from "context";
import Popup from "reactjs-popup";

const Z7 = () => {


  const {
    canGo, setCanGo
  } = useContext(Context);

  


  useEffect(() => {
    setCanGo(true)
  }, [])

  return (
    <div className="z z7">
      <img src={natura} alt="" className="nautra" />
      <div className="rosliny">

      {z7data.map((el) => (
        <Popup
        key={el.id}
          trigger={<img src={el.img} alt="" className="kwiat drag" />}
          position="right center"
        >
          <div className="popup-div">
            <img src={el.img} alt="" className="kwiat drag" />
            <div className="popup-text">
              <h3>{el.title}</h3>
            <p>
              {el.desc}
            </p>
            </div>
        
          </div>
        </Popup>
      ))}
        


      {/* {z7data.map((el) => (
 <img key={el.id} src={el.img} alt="" className="kwiat drag" />
      ))}
        */}
        {/* <img src={krwawnik} alt="" className="kwiat drag" />
        <img src={mniszek} alt="" className="kwiat drag" />
        <img src={piolun} alt="" className="kwiat drag" />
        <img src={pokrzywa} alt="" className="kwiat drag" /> */}
      </div>
    </div>
  );
};

export default Z7;
