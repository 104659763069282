import React from "react";
import MainTemplate from "templates/MainTemplate";
import Dashboard from "views/Dashboard/Dashboard";
import store from "store";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

const Root = () => {
  return (
    <Provider store={store(true)}>
      <MainTemplate>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Dashboard}>
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/" component={Dashboard} />

            {/* default route */}
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </BrowserRouter>
      </MainTemplate>
    </Provider>
  );
};

export default Root;
