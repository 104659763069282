import krolowaImg from "assets/s5/z22/krolowa.svg";

import z3Info from "assets/s1/z3/zalozenie-pasieki-krok-po-kroku.png";

import odzn1 from "assets/odznaka-1.svg";
import odzn2 from "assets/odznaka-2.svg";
import odzn3 from "assets/odznaka-3.svg";
import odzn4 from "assets/odznaka-4.svg";
import odzn5 from "assets/odznaka-5.svg";

import el1 from "assets/pasieka/el1.svg";
import el2 from "assets/pasieka/el2.svg";
import el3 from "assets/pasieka/el3.svg";
import el4 from "assets/pasieka/el4.svg";
import el5 from "assets/pasieka/el5.svg";
import el6 from "assets/pasieka/el6.svg";
import el7 from "assets/pasieka/el7.svg";
import el8 from "assets/pasieka/el8.svg";
import el9 from "assets/pasieka/el9.svg";
import el10 from "assets/pasieka/el10.svg";
import el11 from "assets/pasieka/el11.svg";
import el12 from "assets/pasieka/el12.svg";
import el13 from "assets/pasieka/el13.svg";
import el14 from "assets/pasieka/el14.svg";
import el15 from "assets/pasieka/el15.svg";
import el16 from "assets/pasieka/el16.svg";
import el17 from "assets/pasieka/el17.svg";
import el18 from "assets/pasieka/el18.svg";
import el19 from "assets/pasieka/el19.svg";
import el20 from "assets/pasieka/el20.svg";
import el21 from "assets/pasieka/el21.svg";
import el22 from "assets/pasieka/el22.svg";
import el23 from "assets/pasieka/el23.svg";
import el24 from "assets/pasieka/el24.svg";
import el25 from "assets/pasieka/el25.svg";
import el26 from "assets/pasieka/el26.svg";

import Z1 from "views/Dashboard/Pszczoly/Zadania/Z1";
import Z2 from "views/Dashboard/Pszczoly/Zadania/Z2";
import Z3 from "views/Dashboard/Pszczoly/Zadania/Z3";
import Z4 from "views/Dashboard/Pszczoly/Zadania/Z4";
import Z5 from "views/Dashboard/Pszczoly/Zadania/Z5";
import Z6 from "views/Dashboard/Pszczoly/Zadania/Z6";
import Z7 from "views/Dashboard/Pszczoly/Zadania/Z7";
import Z8 from "views/Dashboard/Pszczoly/Zadania/Z8";
import Z9 from "views/Dashboard/Pszczoly/Zadania/Z9";
import Z10 from "views/Dashboard/Pszczoly/Zadania/Z10";
import Z11 from "views/Dashboard/Pszczoly/Zadania/Z11";
import Z12 from "views/Dashboard/Pszczoly/Zadania/Z12";
import Z13 from "views/Dashboard/Pszczoly/Zadania/Z13";
import Z14 from "views/Dashboard/Pszczoly/Zadania/Z14";
import Z15 from "views/Dashboard/Pszczoly/Zadania/Z15";
import Z16 from "views/Dashboard/Pszczoly/Zadania/Z16";
import Z17 from "views/Dashboard/Pszczoly/Zadania/Z17";
import Z18 from "views/Dashboard/Pszczoly/Zadania/Z18";
import Z19 from "views/Dashboard/Pszczoly/Zadania/Z19";
import Z20 from "views/Dashboard/Pszczoly/Zadania/Z20";
import Z21 from "views/Dashboard/Pszczoly/Zadania/Z21";
import Z22 from "views/Dashboard/Pszczoly/Zadania/Z22";
import Z23 from "views/Dashboard/Pszczoly/Zadania/Z23";
import Z24 from "views/Dashboard/Pszczoly/Zadania/Z24";
import Z25 from "views/Dashboard/Pszczoly/Zadania/Z25";
import Z26 from "views/Dashboard/Pszczoly/Zadania/Z26";
export const zadania = [
  {
    id: 1,
    title: "Z czego składa się ul?",
    introHTML:
      "<p>Każde zwierzę ma swój kąt. Bociany budują wielkie gniazda, krety wykopują swoje jamy głęboko w ziemi. A pszczoły? Czy wiecie gdzie mieszkają pszczoły? W ulu! </p><p>Obejrzyjcie wideo i dowiedzcie się jak wygląda ul i z czego jest zbudowany.</p>",
    sekcja: 1,
    videoID: "cgPbE1Z2TRI",
    startTime: 621,
    endTime: 693,
    zadanieHTML: "<p>Współczesne ule składają się z różnych elementów. Czy pamiętacie z jakich? </p><p>Spróbuj połączyć je z ich cieniami a zobaczycie gdzie mieszkają pszczoły.</p>",
    otwarcie: [],
    element: el1,
    zadanie: <Z1 />,
  },
  {
    id: 2,
    title: "Budowanie ramki",
    introHTML:
      "<p>Pszczoły budują swoje plastry na specjalnie przygotowanych do tego ramkach. </p><p>Stworzenie takiej ramki nie jest takie trudne! Kilka gwoździków, deseczki i trochę węzy. Po więcej informacji sięgnijcie do filmu.</p>",
    sekcja: 1,
    videoID: "cgPbE1Z2TRI",
    startTime: 694,
    endTime: 915,
    zadanieHTML:
      "<p>Trochę zachodu z tymi ramkami! <br/>Tutaj gwoździk, oplątać drutem... wszystko już się pomieszało. </p><p>Czy pamiętacie co trzeba po kolei zrobić? <br/> Ułóżcie etapy tworzenia ramki od tego co trzeba zrobić na początku do tego na końcu</p>",
    gratulacje: {
      tarcza: odzn1,
      title: "Gratulacje!!!",
      tekst: "Robisz pierwsze kroki w pszczelarstwie. Oby tak dalej! Dostajesz odznakę “Pasjonat”",
    },
    otwarcie: [3, 4, 5],
    element: el2,
    zadanie: <Z2 />,
  },
  {
    id: 3,
    title: "Wybór lokalizacji",
    introHTML:
      "<p>Zanim pszczelarz zacznie tworzyć swoją pasiekę musi wybrać odpowiednie miejsce. </p><p>Pszczoły muszą mieć dostosowane warunki do ich wymagań. Obejrzyj film i odkryj gdzie pszczoły czują się najlepiej.</p>",
    sekcja: 1,
    linkInfo: "https://lyson.com.pl/artykuly-pszczelarskie/2019/10/07/zalozenie-pasieki-krok-po-kroku/",
    linkImg: z3Info,
    zadanieHTML:
      "<p>Teraz Wasza kolej! <br/>Wcielicie się w projektantów pasieki. Zdecydujcie, gdzie mają stanąć ule. </p><p>Przeciągnijcie je w odpowiednie miejsce, żeby pszczoły były zadowolone ze swojej okolicy. Pomoże Wam w tym wskaźnik pod planszą.</p>",
    otwarcie: [],
    element: el3,
    zadanie: <Z3 />,
  },
  {
    id: 4,
    title: "Narzędzia pasieczne",
    introHTML:
      "<p>Pszczelarze potrzebują specjalnych narzędzi. Tak jak kucharz potrzebuje noża do krojenia warzyw tak pszczelarz potrzebuje specjalnego kapelusza, który chroni go przed użądleniami. </p><p>Czego jeszcze potrzebuje pszczelarz w swojej pracy? Dowiesz się w filmiku.</p>",
    sekcja: 1,
    videoID: "cgPbE1Z2TRI",
    startTime: 139,
    endTime: 297,
    zadanieHTML:
      "<p>Ale dużo sprzętów potrzebne jest do pracy pszczelarza! Podkurzacze, kapelusz i... widelec. Jakie jeszcze sprzęty pamiętacie? </p><p>Zaznaczcie te elementy, które mogą pomóc w pracy z pszczołami.</p>",
    otwarcie: [],
    element: el4,
    zadanie: <Z4 />,
  },
  {
    id: 5,
    title: "Rodzaje uli",
    introHTML:
      "<p>Pszczelarze wymyślali różne sposoby budowania uli. Niektóre są kolorowe, w kształcie różnych postaci historycznych. Czasami są stworzone ze słomy, drewna a czasami wykorzystuje się tworzywa sztuczne. </p><p>A Tobie? Jaki ul najbardziej się podoba?</p>",
    sekcja: 1,
    videoID: "a2q6p_mmhAQ",
    startTime: 140,
    endTime: 894,
    zadanieHTML:
      "<p>To niesamowite jak różnie wyglądają domy pszczół! </p><p>Czy pamiętasz jakiego były koloru? A czy wiesz z czego jest zrobiona Kószka?</p><p>Spróbujcie swoich sił w memory i połączcie w pary obrazek ula z jego nazwą!</p>",
    otwarcie: [6, 7, 8, 9],
    element: el5,
    zadanie: <Z5 />,
  },
  {
    id: 6,
    title: "Jak pomagać pszczołom",
    introHTML:
      "<p>Czasami może się zdarzyć, że zwierzęta potrzebują naszej pomocy. Pszczoły świetnie radzą sobie na wsi oraz w mieście, ale czy wiesz że możesz pomagać im, aby żyło im się lepiej?</p><p>Obok znajdziecie wideo z naszymi poradami jak można pomagać pszczołom!</p>",
    sekcja: 2,
    videoID: "bJ-ZBbOHvK0",
    zadanieHTML:
      "<p>Ale widok! Kolorowe kamienice...to musi być gdzieś w mieście. </p><p>Przyjrzyjcie się uważnie. O tu! Na dachu! Widzicie? </p><p>Popatrzcie dokładnie na obrazek i obliczcie ile uli widzicie. Wynik podaj na dole.</p>",
    otwarcie: [],
    element: el6,
    zadanie: <Z6 />,
  },
  {
    id: 7,
    title: "Naturalne środki ochrony",
    introHTML:
      "<p>W życiu pszczelarza ważne jest uprawianie ogrodu. Aby rośliny rosły silne i zdrowe należy regularnie je podlewać oraz nawozić. <br/>Nawóz jest to specjalne pożywienie dla roślin, które możemy tworzyć samodzielnie, bez użycia środków chemicznych. </p><p>Zobaczcie jakich roślin używać do naturalnych nawozów!</p>",
    sekcja: 2,
    videoID: "Mt42F10b6ZU",
    startTime: 1,
    endTime: 82,
    zadanieHTML:
      "<p>Rozejrzyjcie się! Czy widzisz tę łąkę? Znajdują się na niej multum roślin - kwiatów, ziół i chwastów. Z niektórych z nich można robić specjalne biopreparaty, które pomagają innym roślinom! </p><p>Kliknijcie w roślinę i odkryjcie jakie zioła i chwasty można wykorzystać w ogrodzie do nawożenia. Następnie, narysuj tę roślinę, która najbardziej przypadła Wam do gustu.<br/>Pamiętaj! Możecie przynieść swoje dzieło do Folwarku, kiedy odwiedzicie nas na warsztatach</p>",
    otwarcie: [],
    element: el7,
    zadanie: <Z7 />,
  },
  {
    id: 8,
    title: "Rójka",
    introHTML:
      "<p>Może się tak czasami zdarzyć, że pszczoły, uciekinierki z ula, zaczną tworzyć plastry na konarach drzew. Mogą też zawitać do Twojego ogródka! <br/>Najważniejsze, żebyście wraz z rodzicami skontaktowali się wtedy do pszczelarza, który zaopiekuje się pszczołami.<br/>Więcej informacji na temat rójki możecie znaleźć na w filmie.</p>",
    sekcja: 2,
    videoID: "zlsyZBdincs",
    startTime: 25,
    endTime: 288,
    zadanieHTML: "<p>Pszczoły rozpoczęły swoją podróż podczas rójki! Muszą przebyć długą drogę, żeby znaleźć najlepsze miejsce. <br/>Pomóżcie pszczołom odnaleźć drogę do drzewa.<br/>Przeciągnij pszczołę do drzewa, a zobaczysz scieżkę.</p>",
    otwarcie: [],
    element: el8,
    zadanie: <Z8 />,
  },
  {
    id: 9,
    title: "Dopajamy pszczoły",
    introHTML:
      "<p>Pszczoły, tak jak każde zwierzęta, potrzebują wody. Podczas cieplejszych dni tej wody może zabraknąć dla naszych zapylaczy, dlatego ważne, żebyśmy im pomagali i tworzyli dla nich poidełka.</p><p>O tym jak takie stworzyć, dowiecie się z filmiku.</p>",
    sekcja: 2,
    videoID: "x3iHS5xW0Bc",
    gratulacje: {
      tarcza: odzn2,
      title: "Gratulacje!!!",
      tekst: "Idzie Ci coraz lepiej! Za swoje zaangażowanie dostajesz odznakę “Hobbysta”",
    },
    zadanieHTML:
      "<p>Jak mogliście się dowiedzieć, poidełko dla pszczół nie jest zwykłą miską z wodą - trzeba umieć ją stworzyć! <br/>Pobierzcie i wydrukujcie pustą listę. Napiszcie na niej złote zasady tworzenia poidełka dla pszczół. <br/>Jeżeli nie macie możliwości wydrukowania - stwórz własną! Postarajcie się użyć kolorowych kredek oraz dodajcie rysunki pszczół!</p>",
    otwarcie: [10],
    element: el9,
    zadanie: <Z9 />,
  },
  {
    id: 10,
    title: "Rośliny miododajne i pyłkodajne",
    introHTML:
      "<p>Czy wiecie, że to dzięki między innymi pszczołom dochodzi do zapylenia roślin? Dzięki swojej pracy pszczoły pozyskują nektar do tworzenia pysznego miodu oraz pyłek do tworzenia pokarmu dla siebie samych. Więcej dowiesz się w filmiku poniżej</p>",
    sekcja: 2,
    videoID: "WAwGV6MrD54",
    startTime: 291,
    endTime: 350,
    zadanieHTML:
      "<p>Już wiesz jakie rośliny są najlepsze dla pszczół! Każdy kwiatek jest innych. Niektóre są duże i wielokolorowe, inne skrywają się w cieniach drzew. <br/>Spróbujcie znaleźć i sparować rośliny z ich cieniami. Następnie odpowiedz na pytanie: Czy są to rośliny miododajne i pyłkodajne? <br/>Informacji możesz poszukać w Internecie lub zapytać kogoś bliskiego!</p>",
    otwarcie: [11, 12, 13, 14],
    element: el10,
    zadanie: <Z10 />,
  },
  {
    id: 11,
    title: "Co produkują pszczoły?",
    introHTML:
      "<p>Oprócz miodu pszczoły wytwarzają też inne rzeczy! Jest to między innymi wosk z którego tworzy się… Jak myślicie, co się tworzy? O wosku, miodzie oraz innych pszczelich produktach dowiesz się z filmiku </p>",
    sekcja: 3,
    videoID: "R0rY6for4Og",
    startTime: 1,
    endTime: 83,
    zadanieHTML:
      "<p>Pszczoły, żeby wyprodukować swoje wytwory muszą się nieźle napracować! Codziennie wylatują w poszukiwaniu nektaru i pyłku pokonując długą drogę. </p><p>Czy pomożecie im wrócić do swoich pasiek? Naciśnij na pszczołę i przeciągnij ją po śladzie, żeby dofrunąć razem do ula!</p>",
    otwarcie: [],
    element: el11,
    zadanie: <Z11 />,
  },
  {
    id: 12,
    title: "Miodobranie i odwirowanie",
    introHTML:
      "<p>Czy wiecie co to jest miodobranie? Jest to proces, w którym pobiera się miód od pszczół. <br/>Ramki pełne słodkości wkłada się do specjalnego bębna i... wychodzi pyszny miodek! <br/>Zobaczcie jak to się robi:</p>",
    sekcja: 3,
    videoID: "8aWDEP8zbGE",
    zadanieHTML:
      "<p>Dawno, dawno temu, na Kurpiach odbywały się huczne uroczystości, podczas których zbierano miód z barci i bawiono się do rana! Podczas tych zabaw śpiewano różne piosenki. Jedna z nich została specjalnie zaszyfrowana, aby tylko bartnicy mogli znać jej tekst. <br/>Jako młodzi adepci pszczelarstwa musicie odkodować tę piosenkę i poznać bartnicze przyśpiewki! <br/>Obok znajduje się klucz kodowy, czyli objaśnienie jakie słowo lub słowa mają swój symbol. <br/>Pod kluczem znajduje się zaszyfrowana piosenka. Wpiszcie pod znakami odpowiednie słowo lub słowa. Uwaga! Możecie potrzebować pomocy kogoś dorosłego</p>",
    otwarcie: [],
    element: el12,
    zadanie: <Z12 />,
  },
  {
    id: 13,
    title: "Konfekcjonowanie miodu",
    introHTML: "<p>Końcowym elementem, który musi zrobić pszczelarz po miodobraniu to pakowanie. Ważne jest, aby miód był przechowywany w odpowiedni sposób! O tym dowiecie się w filmiku.</p>",
    sekcja: 3,
    videoID: "RxpLX0fdjhI",
    zadanieHTML:
      "<p>Każdy słoik miodu musi przedstawiać co znajduje się w środku! Etykiety mogą być różnokolorowe, z wieloma elementami. <br/>Puśćcie wodzę fantazji i zaprojektuj swoją etykietę na słoik miodu. Po ukończeniu, wgraj plik ze swoją etykietą w panelu obok.</p>",
    otwarcie: [],
    element: el13,
    zadanie: <Z13 />,
  },
  {
    id: 14,
    title: "Rodzaje i właściwości rodzajów miodu",
    introHTML:
      "<p>Miód może się różnić - smakiem, kolorem, ale i również zapachem! O jego właściwościach i cechach decyduje to z jakich roślin pszczoły zebrały nektar do ich stworzenia. </p><p>Zobaczcie, jakie rodzaje miodu można spotkać u pszczelarzy</p>",
    sekcja: 3,
    videoID: "SyLzhmRv8nQ",
    startTime: 56,
    endTime: 141,
    zadanieHTML: "<p>Przed Tobą quiz! </p><p>Spróbuj zastanowić się i odpowiedz z nektaru jakiej rośliny zrobione są te rodzaje miodów.</p>",
    gratulacje: {
      tarcza: odzn3,
      title: "Gratulacje!!!",
      tekst: "Robisz coraz większe postępy! Nie masz sobie równych. Chcemy uhonorować Cię odznaką “Adept”",
    },
    otwarcie: [15],
    element: el14,
    zadanie: <Z14 />,
  },
  {
    id: 15,
    title: "Czy miód jest prawdziwy?",
    introHTML:
      "<p>Czasami w sklepach lub na targach możecie trafić na miody sztuczne. Niestety mogą nie mieć tylu ważnych elementów, które pozytywnie wpływają na nasze zdrowie, tak jak prawdziwy miód. </p><p>Zobaczcie jak poznać prawdziwy miód!</p>",
    sekcja: 3,
    videoID: "ke48Of7QCTM",
    zadanieHTML:
      "<p>Prawdziwy miód można wykorzystywać na wiele sposób. Można użyć do słodzenia herbaty, robienia ciastek a nawet....do mięsa! <br/>Przed Wami zadanie kulinarne! Spróbujcie upichcić jakieś danie z miodem. Zróbcie zdjęcia i wyślijcie je wraz z przepisem na naszego maila: <a href='mailto:kontkat@folwarkwawer.pl?Subject=Zadanie 15: Czy miód jest prawdziwy?'>kontkat@folwarkwawer.pl</a> </p>",
    otwarcie: [16, 17, 18, 19],
    element: el15,
    zadanie: <Z15 />,
  },
  {
    id: 16,
    title: "Opieka nad pasieką",
    introHTML:
      "<p>Pszczoły, które hoduje człowiek, potrzebują naszej pomocy. Pszczelarz musi ich doglądać i odpowiadać na ich potrzeby. Podczas wiosny potrzeby pszczół są inne niż podczas zimy. Zobacz, jakie zadania czekają pszczelarza w każdym miesiącu roku:</p>",
    sekcja: 4,
    videoID: "9PnGCUYhpAU",
    startTime: 0,
    endTime: 88,
    zadanieHTML:
      "<p>Ale ciekawostki! Pszczelarz musi mieć ciągle rękę na pulsie. <br/>Przed Wami kalendarz pracy pszczelarza. Spróbuj przeciągnąć brakujące elementy na swoje miejsce. Aby się dowiedzieć o tym, co pszczelarz robi podczas roku kliknijcie w miesiąc na kole.</p>",
    otwarcie: [],
    element: el16,
    zadanie: <Z16 />,
  },
  {
    id: 17,
    title: "Opieka nad rodziną pszczelą",
    introHTML:
      "<p>Zima jest porą roku, kiedy pszczoły mogą potrzebować dokarmiania. Pszczelarze muszą się wykazać spostrzegawczością i wiedzą, żeby zdecydować się na podanie pszczołom dodatkowego pokarmu. </p>",
    sekcja: 4,
    videoID: "_T9YB-ndVs0",
    zadanieHTML:
      "<p>Pszczelarz musi być dobrze przygotowany do przeglądu w pasiece. Musi spełnić kilka ważnych warunków, żeby zrobić go dobrze. Posłuchajcie, zobaczcie obrazek i zdecydujcie czy pszczelarz Wojtek może zabrać się za przegląd? </p>",
    otwarcie: [],
    element: el17,
    zadanie: <Z17 />,
  },
  {
    id: 18,
    title: "Lecznie i pestycydy",
    introHTML:
      "<p>Pszczoły muszą się mierzyć z różnymi zagrożeniami. Czasami związane są z działalnością człowieka a czasami z...pogodą! Zobaczcie, jakie choroby są niebezpieczne dla pszczół oraz czemu pszczołom przydałyby się małe parasolki.</p>",
    sekcja: 4,
    videoID: "exknqkNNFGg",
    zadanieHTML: "<p>Już wiecie, co może zagrażać pszczołom i czemu trzeba stworzyć dla nich małe parasolki! <br/>Z rozsypanki pogrupujcie elementy na pozytywne i negatywne dla pszczół.</p>",
    otwarcie: [],
    element: el18,
    zadanie: <Z18 />,
  },
  {
    id: 19,
    title: "Uloterapia",
    introHTML:
      "<p>Dzięki pszczołom mamy pyszny miód czy wosk do świec. Oprócz produktów tworzonych przez nie służą również do...uloterapi! Dzięki dźwiękom bzyczenia człowiek może zrelaksować się i odpocząć</p>",
    sekcja: 4,
    videoID: "oVzhGtuN56Y",
    startTime: 34,
    endTime: 297,
    zadanieHTML: "<p>Pora na odpoczynek. </p><p>Puście dźwięk pszczół, zamknijcie oczy, pomyślcie o czymś przyjemnym i spróbujcie się odprężyć.</p>",
    otwarcie: [20, 21],
    element: el19,
    zadanie: <Z19 />,
  },
  {
    id: 20,
    title: "Role i zadania",
    introHTML: "<p>W ulu każda pszczoła ma swoje zadanie. Zajmują się zbieraniem pyłku i nektaru, opiekują się larwami czy bronią ula. W filmiku poniżej dowiecie się, jakie są role w pasiece</p>",
    sekcja: 5,
    videoID: "p_UgKxHpodM",
    startTime: 20,
    endTime: 275,
    zadanieHTML: "<p>Ale tłok! Wszędzie pszczoły, zajęte swoimi zadaniami. <br/>Spróbuj policzyć ile jest robotnic, trutni i... czy widzisz królową? Wpisz liczby w odpowiednie miejsca. </p>",
    otwarcie: [],
    element: el20,
    zadanie: <Z20 />,
  },
  {
    id: 21,
    title: "Cykl życia rodziny pszczelej",
    introHTML:
      "<p>Życie pszczół jest bardzo intensywne. Od samego początku wykonuje różne zadania, zaczynając od opiekowaniem się czerwiem. Zobacz jak wygląda cykl życia pszczoły w filmiku obok:</p>",
    sekcja: 5,
    videoID: "c2TmK1-xrLU",
    startTime: 11,
    endTime: 169,
    zadanieHTML:
      "<p>Pszczoły przechodzą długi proces zanim staną się dorosłym owadem. W specjalnych komórkach jedzą i przepoczwarzają się. <br/>Obok znajdują się słowa zapisane drukiem lustrzanym. Gdy przystawicie lusterko do lewej krawędzi słowa, pojawią się na nim w normalnym druku.<br/>Następnie zaznaczcie te słowa, które nie pasują do reszty. Stworzą one cykl rozwoju pszczoły.<br/>Może być Wam potrzebna pomoc kogoś dorosłego!</p>",
    gratulacje: {
      tarcza: odzn4,
      title: "Gratulacje!!!",
      tekst: "Twoje postępy są powalające! W nagrodę dostajesz odznakę “Pomocnik”",
    },
    otwarcie: [22, 23],
    element: el21,
    zadanie: <Z21 />,
  },
  {
    id: 22,
    title: "Kim jest królowa",
    introHTML: "<p>Królowa jest najważniejszą pszczołą w pasiece. Jest doglądana przez robotnice oraz tylko ona składa jajeczka. Możecie dowiedzieć się o niej więcej! Kliknij w filmik:</p>",
    sekcja: 5,
    videoID: "cgPbE1Z2TRI",
    startTime: 1623,
    endTime: 1789,
    image: krolowaImg,
    zadanieHTML:
      "<p>Królowa musi mieć dużo siły, żeby wykonywać wszystkie zadania. To nie lada wyzwanie złożyć dziennie aż 1500 jajeczek! Aby miała na to wszystko energię, robotnicę karmią ją...no właśnie! <br/>Wysłuchaj nagrania i zaznaczcie czym karmiona jest królowa. Następnie wpiszcie jakie jest Wasze ulubione danie, które daje Ci energię na cały dzień!</p>",
    otwarcie: [],
    element: el22,
    zadanie: <Z22 />,
  },
  {
    id: 23,
    title: "Pszczeli taniec",
    introHTML:
      "<p>My ludzie, komunikujemy się ze sobą głównie słowami. Krzyczymy: “Ale super!”, kiedy coś nam się spodoba. Pszczoły natomiast komunikują się poprzez...taniec! Wykonując określone ruchy przekazują dalej informacje o lokalizacji kwiatów! <br/>Zobaczcie jakie ruchy co oznaczają!</p>",
    sekcja: 5,
    videoID: "9ldM4vtVEbI" ,
    // startTime: 180,
    // endTime: 185,
    zadanieHTML: "<p>Przed Wami zadanie taneczne! <br/>Zatańczcie tak jak pszczoły w rytm muzyki. </p><p>Śmiało! </p><p>Zróbcie trochę miejsca w pokoju i zatańczcie razem z pszczołami.  </p>",
    otwarcie: [24, 25],
    element: el23,
    zadanie: <Z23 />,
  },
  {
    id: 24,
    title: "Budowanie hoteli dla owadów",
    introHTML:
      "<p>Inne owady zapylające też muszą mieć swój dom. Czy wiesz, że możemy im pomagać tworząc dla nich hotele? Trochę słomy, siana i specjalnie przygotowanych kawałków drewna i mamy nasze miejsce dla owadów! Po całą instrukcję zajrzyjcie do filmiku obok.</p>",
    sekcja: 6,
    videoID: "cgPbE1Z2TRI",
    zadanieHTML:
      "<p>Każdy hotel musi mieć pewne stałe części, aby owady czuły się w nim dobrze. Czy pamiętasz jakie to były?<br/>Pobierz obrazek na dole, wydrukuj go a następnie dorysuj co powinno się znajdować w takim hotelu. <br/>Jeżeli nie możesz wydrukować. Narysuj swój hotel dla owadów! <br/>Przy kolejnym spacerze koło Folwarku, przynieście swoje dzieło!</p>",
    otwarcie: [],
    element: el24,
    zadanie: <Z24 />,
  },
  {
    id: 25,
    title: "Gatunki pszczół",
    introHTML:
      "<p>W pszczelej rodzinie możemy znaleźć wiele gatunków pszczół. Nie wszystkie są hodowane przez człowieka. Niektóre z nich żyją dziko w lasach czy łąkach. <br/>Macie teraz okazję dowiedzieć się o nich więcej!</p>",
    sekcja: 6,
    videoID: "cgPbE1Z2TRI",
    startTime: 180,
    endTime: 185,
    zadanieHTML:
      "<p>Wszystkie te ich nazwy są całkiem trudne do wypowiedzenia…<br/>Żywi...jak? <br/>Ście...co?<br/>Czy potrafisz je powiedzieć? Kliknij na nagranie i powtórz dokładnie i wyraźnie ich nazwy a będziesz prawdziwym mistrzem pszczelej dykcji!</p>",
    otwarcie: [26],
    element: el25,
    zadanie: <Z25 />,
  },
  {
    id: 26,
    title: "Inne owady zapylające",
    introHTML: "<p>Nie tylko pszczoły mogą zapylać rośliny. W świecie zwierząt są też inne owady, które możemy zaliczyć do grupy “zapylaczy”. Warto znać ich rolę w ekosystemie!</p>",
    sekcja: 6,
    videoID: "cgPbE1Z2TRI",
    startTime: 180,
    endTime: 185,
    zadanieHTML:
      "<p>Jakie zaskoczenie, że te owady, które widujemy na co dzień na wiosnę oraz w lecie są zapylaczami! Teraz już wiecie jak je rozpoznać. </p><p>Połączcie obrazki tych owadów z ich nazwami.</p>",
    gratulacje: {
      tarcza: odzn5,
      title: "Gratulacje!!!",
      gratulacje: "Poskromiłeś już prawie wszystkie tajniki pszczelarstwa. Za wytrwałość otrzymujesz odznakę “Czeladnik”",
    },
    otwarcie: [],
    element: el26,
    zadanie: <Z26 />,
  },
];
