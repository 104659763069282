import s1Info from "assets/s1/info.svg";
import s2Info from "assets/s2/info.svg";
import s3Info from "assets/s3/info.svg";
import s4Info from "assets/s4/info.svg";
import s5Info from "assets/s5/info.svg";
import s6Info from "assets/s6/info.svg";

//DODAĆ DO PASIEKI
// import odzn6 from "assets/odznaka-6.svg";
// gratulacje: {
//   odznaka: odzn6,
//   teskt: "Jesteś prawdziwym MISTRZEM pszczelarstwa. Gratulacje!!",
// },

import medal1 from "assets/medal-1.svg";
import medal2 from "assets/medal-2.svg";
import medal3 from "assets/medal-3.svg";
import medal4 from "assets/medal-4.svg";
import medal5 from "assets/medal-5.svg";
import medal6 from "assets/medal-6.svg";

export const sekcje = [
  {
    id: 1,
    nazwa: "Wytwórca",
    image: s1Info,
    okrzyk: {
      odznaka: medal1,
      title: "Brawo!!!",
      tekst: "Dostałeś odznakę “Wytwórca”. Wiesz już bardzo dużo jak stworzyć pasiekę i jak się nią opiekować.",
    },
    suma: 5,
  },
  {
    id: 2,
    nazwa: "Ogrodnik",
    image: s2Info,
    okrzyk: {
      odznaka: medal2,
      title: "Dobra robota!",
      tekst: "Tajniki roślin miododajnych i pyłkodajnych to dla Ciebie bułka z masłem. Brawa za dostanie odznaki “Ogrodnik”!",
    },
    suma: 10,
  },
  {
    id: 3,
    nazwa: "Smakosz",
    image: s3Info,
    okrzyk: {
      odznaka: medal3,
      title: "Ekstra!",
      tekst: "Od teraz jesteś prawdziwym “Smakoszem”! Jesteś prawdziwym specjalistą od miodów",
    },
    suma: 15,
  },
  {
    id: 4,
    nazwa: "Opiekun",
    image: s4Info,
    okrzyk: {
      odznaka: medal4,
      title: "Świetnie!",
      tekst: "Wiesz już jak zajmować się pszczołami. W nagrodę dostajesz odznakę “Opiekun”",
    },
    suma: 19,
  },
  {
    id: 5,
    nazwa: "Znawca",
    image: s5Info,
    okrzyk: {
      odznaka: medal5,
      title: "Wow!",
      tekst: "Twoja wiedza o pszczołach nie ma sobie równych. “Znawca” - to jest odznaka, którą możesz się pochwalić innym!",
    },
    suma: 23,
  },
  {
    id: 6,
    nazwa: "Sojusznik",
    image: s6Info,
    okrzyk: {
      odznaka: medal6,
      title: "Gratulacje!",
      tekst: "Jesteś prawdziwym znawcą zapylaczy! Oto twoja odznaka “Sojusznik”",
    },
    suma: 26,
  },
];
