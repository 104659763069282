import React from "react";

import "./TwojeOdznaki.scss";
const TwojeOdznaki = (props) => {
  const { medale, tarcza } = props;
  return (
    <>
      <div className="odznaki">
        <div className="odznaki__title">Twoje odznaki</div>
        <div className="odznaki__lista">
          {tarcza && (
            <div className="odznaki__tarcza">
              <img src={tarcza} alt="odznaka" />
            </div>
          )}
          {medale && medale.map((medal) => <img src={medal} alt="odznaka" key={medal} />)}
        </div>
      </div>
    </>
  );
};

export default TwojeOdznaki;
