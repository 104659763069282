import React, {  useEffect, useContext } from "react";
import { Context } from "context";

const Z15 = () => {

  const {
    canGo, setCanGo
  } = useContext(Context);

  useEffect(() => {
    setCanGo(true)
  }, [])

  return (
    <div className="z z15">
       <a href="mailto:kontkat@folwarkwawer.pl?Subject=Zadanie 15: Czy miód jest prawdziwy?">wyślijcie e-mail na adres kontkat@folwarkwawer.pl</a>
    </div>
  );
};

export default Z15;
