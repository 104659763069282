import React, {useState, useContext, useEffect} from "react";
import audio from "assets/s4/z19/audio.svg";
import pszczola from "assets/s4/z19/pszczola.svg";
import Sound from 'react-sound';
import { Context } from "context";
import pszcz from "assets/pszczoly.mp3"

const Z19 = () => {

  const [isPlaying, setIsPlaying] = useState(false)

  const {
    canGo, setCanGo
  } = useContext(Context);




  useEffect(() => {
    setCanGo(false)
  }, [])


  return (
    <div className="z z19">
      <Sound 
        playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
        url={pszcz}
        volume={80}
        />
      <div className="glosnik" >
        <img id="to" className="glos_img" src={audio} alt=""  style={{opacity: isPlaying ? "1" : "0.6"}}  onClick={() => {
          setIsPlaying(!isPlaying);
          setCanGo(true)
          }}/>
        <h3 style={{opacity: isPlaying ? "1" : "0.6"}}  onClick={() => {
          setIsPlaying(!isPlaying);
          setCanGo(true)
          }}>Odtwórz dźwięk pszczół</h3>


        <img src={pszczola} alt="" className="pszczola p1" />
      <img src={pszczola} alt="" className="pszczola p2" />
      <img src={pszczola} alt="" className="pszczola p3" />
      <img src={pszczola} alt="" className="pszczola p4" />
      <img src={pszczola} alt="" className="pszczola p5" />

      </div>
    </div>
  );
};

export default Z19;
