//import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactProgressLineBar from "react-progress-line-bar";
import { zaktualizujElementy as zaktualizujElementyAction } from "actions/gameActions";
import { zaktualizujRozwiazanie as zaktualizujRozwiazanieAction } from "actions/gameActions";
import pole from "assets/s1/z3/polele.svg";
import { Context } from "context";
import { useDrag } from "react-dnd";
// import cx from "classnames";
import confused from "assets/s1/z3/confused.svg";
import sad from "assets/s1/z3/sad.svg";
import neutral from "assets/s1/z3/neutral.svg";
import wink from "assets/s1/z3/wink.svg";
import grinning from "assets/s1/z3/grinning.svg";
import update from "immutability-helper";

import { useDrop } from "react-dnd";

export const Box = ({ id, left, top, position, children }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: "ItemTypes.BOX" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <div className="cards" ref={drag} style={{ left, top, position }}>
      {children}
    </div>
  );
};

const Z3 = ({ elementy, zaktualizujElementy, zaktualizujRozwiazanie }) => {
  const [ele, setEle] = useState([]);

  const { canGo, setCanGo } = useContext(Context);

  const [pos, setPos] = useState(0);
  const [bcol, setBcol] = useState("#ff0016");
  const [whichPic, setWhichPic] = useState(sad)

  useEffect(() => {
    setCanGo(false);
  }, []);

  useEffect(() => {
    // const first = elementy[0]
    let count = 0;
    elementy.map((first, index) => {
      const x = first.left + 0.5 * first.width;
      const y = first.top + 0.5 * first.height;

      // const x2 = first.posLeft + 0.5 * first.width
      // const y2 = first.posTop + 0.5 * first.height
      const a = first.posLeft;
      const b = first.posTop;

      const c = first.posLeft + first.posWidth;
      const d = first.posTop + first.posHeight;

      if (x > a && x < c && y > b && y < d) {
        count += 1;
        setPos(count);
        // first.left = first.posLeft
        // first.top = first.posTop
      } else {
        setPos(count);
      }

      // console.log(y2-y1)
      // console.log(x2-x1)
      // console.log(Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)))
    });

    if (pos === 4) {
      setCanGo(true);
    } else {
      setCanGo(false);
    }
  }, [elementy, pos, setCanGo]);

  const history = useHistory();
  // console.log(elementy);

  // console.log(pos)

  const [collectedProps, drop] = useDrop({
    accept: "ItemTypes.BOX",
    drop(item, monitor) {
      const start_pos = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      setEle(item);
      // console.log(start_pos, delta);
      const left = item.left
        ? Math.round(item.left + delta.x)
        : start_pos.x + delta.x - 150;
      const top = item.top
        ? Math.round(item.top + delta.y)
        : start_pos.y + delta.y - 250;

      moveBox(item.id, left, top);
      return undefined;
    },
  });

  // console.log()
  const moveBox = (id, left, top) => {
    var el = update(elementy, {
      [id]: {
        $merge: { left, top, position: "absolute" },
      },
    });
    // console.log(el);

    zaktualizujRozwiazanie(el, 3);
  };

  useEffect(() => {
    if (pos === 0) {
      setBcol("zero");
      setWhichPic(confused)
    } else if (pos === 1) {
      setBcol("quater");
      setWhichPic(sad)
    } else if (pos === 2) {
      setBcol("half");
      setWhichPic(neutral)
    } else if (pos === 3) {
      setBcol("threequaters");
      setWhichPic(wink)
    }else if (pos === 4) {
      setWhichPic(grinning)
    }
  }, [pos]);

  console.log(pos);

  return (
    <div className="z z3">
      <div className="pole" ref={drop}>
        {" "}
        <img src={pole} alt="cień" />
        <div className="male_pole"> </div>
      </div>
      
      <div className="drag">
        <div className="ule-txt">
          <h3>Ustaw ule we właściwym miejscu.</h3>
        </div>
        <div className="ule">
          {Object.keys(elementy).map((key) => {
            const { left, top, position, image, klasa } = elementy[key];
            // console.log(key);

            return (
              <Box key={key} id={key} left={left} top={top} position={position}>
                <img className={klasa} src={image} alt="" />
              </Box>
            );
          })}
        </div>
        <div className="smiles">
          <img className="smiles" src={whichPic} alt="" />
        </div>
      </div>
      
      <div className={pos === 4 ? "full" : bcol}>
        <ReactProgressLineBar value={pos === 0 ? 2 : pos * 25} />
      </div>
      {/* 
      <img src={u1} alt="1" />
      <img src={u2} alt="2" />
      <img src={u3} alt="3" />
      <img src={u4} alt="4" /> */}

      {/* 
      <h1 className="pasieka__title">Dokończ budowę swojej pasieki</h1>
      <div className="pasieka__budowa" ref={drop}>
        <img src={pasieka} alt="pasieka" />

        <div className="elementy">
          <div className="elementy__lista">
            {Object.keys(elementy).map((key) => {
              const { left, top, position, image } = elementy[key];
              console.log(key);
              return (
                <Box key={key} id={key} left={left} top={top} position={position}>
                  <img src={image} alt="" />
                </Box>
              );
            })}
          </div>
        </div>
      </div> */}
    </div>
  );
};

Z3.propTypes = {};

const mapStateToProps = (state) => {
  // console.log(state.gameReducer);
  return {
    elementy: state.gameReducer.rozwiazane_zadania[2].elementy,
    rozwiazane_zadanie: state.gameReducer.rozwiazane_zadania[2],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaktualizujRozwiazanie: (elementy, zadanieID) =>
      dispatch(zaktualizujRozwiazanieAction(elementy, zadanieID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Z3);
