import React, { useState, useContext, useEffect } from "react";

import audio from "assets/s4/z17/audio.svg";
import Wojtek from "assets/s4/z17/Wojtek.svg";
import woj from "assets/wojciech_pszcz.mp3"
import cx from "classnames";
import Sound from 'react-sound';

import { Context } from "context";

const Z17 = () => {

  const [isPlaying, setIsPlaying] = useState(false)
  const [isBtn, setIsBtn] = useState()

  const {
    canGo, setCanGo
  } = useContext(Context);

   


  useEffect(() => {
    setCanGo(false)
  }, [])

  useEffect(() => {
    if(isBtn === false) {
      setCanGo(true)
    } else {
      setCanGo(false)
    }
  }, [isBtn, setCanGo])

  return (
    <div className="z z17">
      <div className="info">
        <Sound 
        playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
        url={woj}
        volume={80}
        />
        <div className="glosnik">
        <img src={audio} alt="" 
        onClick={() => setIsPlaying(!isPlaying)}
        />
        <h3
        onClick={() => setIsPlaying(!isPlaying)}
        >Odtwórz</h3>
        </div>
       
        <p>
          Pszczelarz Wojtek obudził się w zimny marcowy dzień. Wstał, rozprostował się i spojrzał przez okno. Zapowiada się na deszcz! Spojrzał na termometr - 5 stopni. Zaczął się ubierać. Zjadł
          śniadanie takie jak zwykle - tosty z miodem. W trakcie jedzenia usłyszał dźwięk telefonu. To jego przyjaciel Bartek! Zadzwonił, żeby uprzedzić Wojtka, że dopiero jutro odda mu pożyczone od
          niego podkurzacz, widelec oraz kombinezon. Po rozmowie z przyjacielem Wojtek wyszedł na ganek i zaczął się zastanawiać: Czy dzisiaj jest dzień na wiosenny przegląd?
        </p>
      </div>
      <div className="Wojtek">
        <img src={Wojtek} alt="" />
        <h3>Czy pszczelarz Wojtek może zabrać się za przegląd?</h3>
        <div className="pytanie">
          <button className={cx("btn", isBtn === false ? "active" : "" )} onClick={() => setIsBtn(false)} >NIE</button>
          <button className={cx("btn", isBtn === true ? "active" : "" )} onClick={() => setIsBtn(true)} >TAK</button>
        </div>
      </div>
    </div>
  );
};

export default Z17;
