//import PropTypes from "prop-types";
import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Context } from "context";
import { zaliczoneZadanieService } from "services/gameServices";

import { sekcje } from "data/sekcje";
import { zadania } from "data/zadania";
import arr from "assets/arr.svg";

import "./Zadanie.scss";

// const StyledWrapper = styled(Grid)`
//   && {
//     margin-top: 4rem;
//   }
// `;

const Zadanie = ({
  match: { params },
  zaliczoneZadanie,
  rozwiazane_zadania,
}) => {
  const history = useHistory();
  const [rozwiazuje, setRozwiazuje] = useState(false);

  const { canGo, setCanGo } = useContext(Context);

  //POPRAWIĆ
  const zadanie = zadania[params.id - 1];
  const sekcja = sekcje[zadanie.sekcja - 1];
  //
  // console.log(rozwiazane_zadania[zadanie.id - 1].aktywne);
  // console.log(zadanie.zadanie);
  return (
    <div className="zadanie">
      {rozwiazane_zadania[zadanie.id - 1].aktywne ? (
        <>
          <h1 className="zadanie__title">
            {params.id}. {zadanie.title}
          </h1>
          {!rozwiazuje && (
            <div className="zadanie__informacje">
              <div className="zadanie__video">
                {zadanie.videoID && (
                  <div className="videoWrapper">
                    <iframe
                      src={
                        "https://www.youtube.com/embed/" +
                        zadanie.videoID +
                        "?autoplay=1&showinfo=0&rel=0&controls=0&fs=1" +
                        (zadanie.startTime
                          ? "&start=" + zadanie.startTime
                          : "") +
                        (zadanie.endTime ? "&end=" + zadanie.endTime : "")
                      }
                      frameBorder="0"
                      title="video"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
                {zadanie.linkInfo && (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a
                    href={zadanie.linkInfo}
                    className="zadanie__infolink"
                    target="_blank"
                  >
                    {zadanie.linkImg && <img src={zadanie.linkImg} alt=""  style={{width: "100%"}}/>}
                    {zadanie.linkInfo}
                  </a>
                )}
              </div>
              <div className="zadanie__opis">
                {zadanie.image && <img src={zadanie.image} alt=""  />}
                {!zadanie.image && <img src={sekcja.image} alt=""  />}
                <div
                  className="zadanie__html"
                  dangerouslySetInnerHTML={{ __html: zadanie.introHTML }}
                ></div>

                <div className="zadanie__nav">
                  <div
                    className="wroc"
                    onClick={() => history.push("/pszczoly")}
                  >
                    <span className="arrow arrow-left"></span> wróć
                  </div>
                  <button
                    className="dalej squared"
                    onClick={() => setRozwiazuje(true)}
                  >
                    rozwiąż zadanie <span className="arrow"></span>
                  </button>
                </div>
                
              </div>
            </div>
          )}
          {rozwiazuje && (
            <div className="zadanie__rozwiazuje">
              <div className="zadanie__pole">
                {zadanie.zadanie ? zadanie.zadanie : "treść"}
              </div>
              <div className="zadanie__opis">
                <img src={sekcja.image} alt="" />
                {zadanie.id === 3 ? (
                  <div
                    className="zadanie__html rd"
                    dangerouslySetInnerHTML={{ __html: zadanie.zadanieHTML }}
                  ></div>
                ) : (
                  <div
                    className="zadanie__html"
                    dangerouslySetInnerHTML={{ __html: zadanie.zadanieHTML }}
                  ></div>
                )}
                <div className="zadanie__nav">
                  <div className="wroc" onClick={() => setRozwiazuje(false)}>
                    <span className="arrow arrow-left"></span> wróć
                  </div>
                  {canGo ? (
                    <button
                      className="dalej squared"
                      onClick={() => {
                        zaliczoneZadanie(zadanie.id);
                        history.push("/pszczoly");
                      }}
                    >
                      dalej <span className="arrow"></span>
                    </button>
                  ) : (
                    <button className=" squared" style={{ opacity: "0.5" }}>
                      dalej <span className="arrow"></span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="wrong" >
          <div className="wrong-inside" onClick={() => history.push("/pszczoly")}>
          <img className="swing" src={arr} alt="arrow" />
          <h2>
            Zadanie nieaktywne. <span>Wróć do menu</span>
          </h2>
          </div>
        </div>
      )}
    </div>
  );
};

Zadanie.propTypes = {};

const mapStateToProps = (state) => {
  return {
    rozwiazane_zadania: state.gameReducer.rozwiazane_zadania,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaliczoneZadanie: (id) => dispatch(zaliczoneZadanieService(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Zadanie);
