//import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Context } from "context";
import { zaktualizujRozwiazanie as zaktualizujRozwiazanieAction } from "actions/gameActions";

import { useDrag } from "react-dnd";

import update from "immutability-helper";

import { useDrop } from "react-dnd";

export const Box = ({ id, left, top, position, children }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: "ItemTypes.BOX" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <div ref={drag} style={{ left, top, position }}>
      {children}
    </div>
  );
};

const Z4 = ({ elementy, zaktualizujRozwiazanie }) => {

  const [whichElement, setWhichElement] = useState([])

  const arr = [2,4,5,7,8,9,10,12]
  const arrNot = [1,3,6,11]

  const {
    canGo, setCanGo
  } = useContext(Context);

   const [pos, setPos] = useState()


  useEffect(() => {
    setCanGo(false)
  }, [])

  useEffect(() => {
    let count = 0

    arr.map((el) => {
      if (whichElement.includes(el)){
        count += 1;
      
      }
   
    })
    setPos(count)


    
    if(pos === 8 && (whichElement.includes(1) || whichElement.includes(3) || whichElement.includes(6) || whichElement.includes(11))) {
      setCanGo(false)
    }else if( pos===8)
    setCanGo(true)
    else {
      setCanGo(false)
    }

    

  }, [pos, whichElement])

  // console.log(elementy);
console.log(whichElement)
console.log(pos)
  return (
    <div className="z z4">
      {elementy &&
        Object.keys(elementy).map((key) => {
          const { image, active, id } = elementy[key];
          

          return (
            <div className={"element " + (active ? "active" : "")} key={key}>
              <img
                src={image}
                alt=""
                onClick={(e) => {
                  var el = update(elementy, {
                    [key]: {
                      $merge: { active: !active },
                    },
                  });
                  zaktualizujRozwiazanie(el, 4);
                  if(!whichElement.includes(id)) {
                    setWhichElement(oldArray => [...oldArray, id])
                  } else if (whichElement.includes(id)) {
                    setWhichElement(whichElement.filter(item => item !== id))
                  }
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

Z4.propTypes = {};

const mapStateToProps = (state) => {
 
  return {
    elementy: state.gameReducer.rozwiazane_zadania[3].elementy,
    rozwiazane_zadanie: state.gameReducer.rozwiazane_zadania[3],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaktualizujRozwiazanie: (elementy, zadanieID) => dispatch(zaktualizujRozwiazanieAction(elementy, zadanieID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Z4);
