import React, { useState, useEffect, useContext } from "react";
import ico_pdf from "assets/s6/z24/pdf.svg";
import { Context } from "context";

const Z24 = () => {

  const {
    canGo, setCanGo
  } = useContext(Context);

   const [pos, setPos] = useState()


  useEffect(() => {
    setCanGo(false)
  }, [])


  return (
    <div className="z z24 z9">
      <a href="/pdf/Hotel_dla_pszczol.pdf" download="Hotel_dla_pszczol.pdf">
        <img src={ico_pdf} alt="" onClick={() =>     setCanGo(true)} />
        <h3 onClick={() =>     setCanGo(true)} >Pobierz plik pdf</h3>
      </a>
    </div>
  );
};

export default Z24;
