import React, {useEffect, useContext, useState} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Context } from "context";
import { zaktualizujElementy as zaktualizujElementyAction } from "actions/gameActions";

import { zaktualizujRozwiazanie as zaktualizujRozwiazanieAction } from "actions/gameActions";

import { useDrag } from "react-dnd";

import update from "immutability-helper";

import { useDrop } from "react-dnd";

export const Box = ({ id, left, top, position, children }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: "ItemTypes.BOX" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <div className="cards" ref={drag} style={{ left, top, position }}>
      {children}
    </div>
  );
};

const Z18 = ({ elementy, zaktualizujElementy, zaktualizujRozwiazanie }) => {


  const {
    canGo, setCanGo
  } = useContext(Context);

   const [pos, setPos] = useState()


  useEffect(() => {
    setCanGo(false)
  }, [])


  useEffect(() => {
    // const first = elementy[0]
    let count = 0;
    elementy.map( (first, index) => {
    const x1 = first.left + 0.5 * first.width
    const y1 = first.top + 0.5 * first.height
  
    const x2 = first.posLeft + 0.5 * first.width
    const y2 = first.posTop + 0.5 * first.height

    const x3 = first.posLeft2 +0.5 * first.width
    const x4 = first.posLeft3 +0.5 * first.width
  
    if (Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)) < 50){
      count += 1;
      setPos(count) 
      first.left = first.posLeft
      first.top = first.posTop
    }else if (Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x3-x1, 2)) < 30) {
      count += 1;
      setPos(count) 
      first.left = first.posLeft2
      first.top = first.posTop
    
  }else if (Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x4-x1, 2)) < 30) {
    count += 1;
    setPos(count) 
    first.left = first.posLeft2
    first.top = first.posTop
  }
    else {
      setPos(count)
    }
  
  
    // console.log(y2-y1)
    // console.log(x2-x1)
  // console.log(Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)))
  })
  
  if(pos === 5) {
    setCanGo(true)
  }else {
    setCanGo(false)
  }
  
  }, [elementy, pos, setCanGo])



  const [collectedProps, drop] = useDrop({
    accept: "ItemTypes.BOX",
    drop(item, monitor) {
      const start_pos = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      // console.log(start_pos, delta);
      const left = item.left
        ? Math.round(item.left + delta.x)
        : start_pos.x + delta.x - 200;
      const top = item.top
        ? Math.round(item.top + delta.y)
        : start_pos.y + delta.y - 250;

      moveBox(item.id, left, top);
      return undefined;
    },
  });
  const moveBox = (id, left, top) => {
    var el = update(elementy, {
      [id]: {
        $merge: { left, top, position: "absolute" },
      },
    });
    // console.log(el);

    zaktualizujRozwiazanie(el, 18);
    // console.log(elementy);
  };

  return (
    <div className="z z18" ref={drop}>
      <div>
        {Object.keys(elementy).map((key) => {
          const { left, top, position, image, klasa, desc } = elementy[key];
          // console.log(key);
          return (
            <Box key={key} id={key} left={left} top={top}>
              <img className={klasa} src={image} alt="" />
              <p>{desc}</p>
            </Box>
          );
        })}
      </div>

      <div className="drop-cont">
        <div class="pozytywne">
          <div className="koszyki">
            <div class="drop d1"></div>
            <div class="drop d2"></div>
          </div>
          <h3>Pozytywne</h3>
        </div>
        <div class="negatywne">
          <div className="koszyki">
            <div class="drop d3"></div>
            <div class="drop d4"></div>
            <div class="drop d5"></div>
          </div>
          <h3>Negatywne</h3>
        </div>
      </div>

      {/* <div class="drag">
        <img src={deszcz} alt="" />
        Deszcz
      </div>
      <div class="drag">
        <img src={ul} alt="" />
        dobry ul
      </div>
      <div class="drag">
        <img src={roztocza} alt="" />
        roztocza
      </div>
      <div class="drag">
        <img src={susza} alt="" />
        susza
      </div>
      <div class="drag">
        <img src={kwiaty} alt="" />
        rośliny
      </div>
      */}
    </div>
  );
};

Z18.propTypes = {};

const mapStateToProps = (state) => {
  // console.log(state.gameReducer);
  return {
    elementy: state.gameReducer.rozwiazane_zadania[17].elementy,
    rozwiazane_zadanie: state.gameReducer.rozwiazane_zadania[17],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaktualizujRozwiazanie: (elementy, zadanieID) =>
      dispatch(zaktualizujRozwiazanieAction(elementy, zadanieID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Z18);
