import React, {useContext, useEffect} from "react";
import { Context } from "context";
import ico_pdf from "assets/s2/z9/pdf.svg";

const Z9 = () => {

  const {
    canGo, setCanGo
  } = useContext(Context);

  useEffect(() => {
    setCanGo(false)
  }, [])


  return (
    <div className="z z9">
      <a href="/pdf/Poidlo_dla_pszczol.pdf" download="Poidlo_dla_pszczol.pdf" 
      onClick={() => setCanGo(true)}
      >
        <img src={ico_pdf} alt="" /> <br />
        <h3>Pobierz plik pdf</h3>
      </a>
    </div>
  );
};

export default Z9;
