import { ADD_MEDAL, ZAKTUALIZUJ_ELEMENTY, ZAKUTALIZUJ_ROZWIAZANIE, SET_TARCZA, SHOW_MODAL, HIDE_MODAL, ZALICZ_ZADANIE, ADD_ELEMENT } from "constants/actionTypes";

import stale1 from "assets/pasieka/czlowiek1.svg";
import stale2 from "assets/pasieka/czlowiek2.svg";
import stale3 from "assets/pasieka/czlowiek3.svg";
import stale4 from "assets/pasieka/drzewa_3.svg";
import stale5 from "assets/pasieka/drzewo_1.svg";
import stale6 from "assets/pasieka/drzewo_2.svg";
import stale7 from "assets/pasieka/dziecko_1.svg";
import stale8 from "assets/pasieka/dziecko_2.svg";
import stale9 from "assets/pasieka/dzrewa_dwa.svg";
import stale10 from "assets/pasieka/trawa.svg";
import stale11 from "assets/pasieka/kobieta.svg";

import z1el1 from "assets/s1/z1/cz1.svg";
import z1el2 from "assets/s1/z1/cz2.svg";
import z1el3 from "assets/s1/z1/cz3.svg";
import z1el4 from "assets/s1/z1/cz4.svg";
import z1el5 from "assets/s1/z1/cz5.svg";
import z1el6 from "assets/s1/z1/cz6.svg";
import z1el7 from "assets/s1/z1/cz7.svg";

import z4el1 from "assets/s1/z4/n1.svg";
import z4el2 from "assets/s1/z4/n2.svg";
import z4el3 from "assets/s1/z4/n3.svg";
import z4el4 from "assets/s1/z4/n4.svg";
import z4el5 from "assets/s1/z4/n5.svg";
import z4el6 from "assets/s1/z4/n6.svg";
import z4el7 from "assets/s1/z4/n7.svg";
import z4el8 from "assets/s1/z4/n8.svg";
import z4el9 from "assets/s1/z4/n9.svg";
import z4el10 from "assets/s1/z4/n10.svg";
import z4el11 from "assets/s1/z4/n11.svg";
import z4el12 from "assets/s1/z4/n12.svg";

import u1 from "assets/s1/z3/u1.svg";
import u2 from "assets/s1/z3/u2.svg";
import u3 from "assets/s1/z3/u3.svg";
import u4 from "assets/s1/z3/u4.svg";


import z51 from "assets/s1/z5/kloda_bartna.svg";
import z52 from "assets/s1/z5/koszka_ze_slomy.svg";
import z53 from "assets/s1/z5/ul_figuralny.svg";
import z54 from "assets/s1/z5/ul_snozowy.svg";


import z10 from "assets/s2/z10/aster.svg";
import z11 from "assets/s2/z10/jezowka.svg";
import z12 from "assets/s2/z10/lawenda.svg";
import z13 from "assets/s2/z10/mak.svg";
import z14 from "assets/s2/z10/wrzos.svg";

import deszcz from "assets/s4/z18/deszcz.svg";
import kwiaty from "assets/s4/z18/kwiaty.svg";
import roztocza from "assets/s4/z18/roztocza.svg";
import susza from "assets/s4/z18/susza.svg";
import ul from "assets/s4/z18/ul.svg";

import motyl from "assets/s6/z26/motyl.svg";
import muchowka from "assets/s6/z26/muchowka.svg";
import osa from "assets/s6/z26/osa.svg";
import szerszen from "assets/s6/z26/szerszen.svg";
import trzmiel from "assets/s6/z26/trzmiel.svg";

import pszczola from "assets/s3/z11/pszczola.svg";
import pszczola112 from "assets/s2/z8/pszczola.svg";


import sty from "assets/s4/z16/styczen.mp3"
import lu from "assets/s4/z16/luty.mp3"
import marz from "assets/s4/z16/marzec.mp3"
import kw from "assets/s4/z16/kwiecien.mp3"
import maj from "assets/s4/z16/maj.mp3"
import cze from "assets/s4/z16/czerwiec.mp3"
import lip from "assets/s4/z16/lipiec.mp3"
import sier from "assets/s4/z16/sierpien.mp3"
import wrz from "assets/s4/z16/wrzesien.mp3"
import paz from "assets/s4/z16/pazdziernik.mp3"
import lis from "assets/s4/z16/listopad.mp3"
import gru from "assets/s4/z16/grudzien.mp3"


const initialState = 
sessionStorage.getItem("gra_folwark")
  ? JSON.parse(sessionStorage.getItem("gra_folwark")).gameReducer
  : 
  {
      medale: [],
      elementy: [
        { image: stale1, position: "static", left: null, top: null },
        { image: stale2, position: "static", left: null, top: null },
        { image: stale3, position: "static", left: null, top: null },
        { image: stale4, position: "static", left: null, top: null },
        { image: stale5, position: "static", left: null, top: null },
        { image: stale6, position: "static", left: null, top: null },
        { image: stale7, position: "static", left: null, top: null },
        { image: stale8, position: "static", left: null, top: null },
        { image: stale9, position: "static", left: null, top: null },
        { image: stale10, position: "static", left: null, top: null },
        { image: stale11, position: "static", left: null, top: null },
      ],
      tarcza: null,
      rozwiazane_zadania: [
        {
          id: 1,
          aktywne: true,
          status: false,
          elementy: [
            { id: 0, image: z1el1, klasa: "k1", position: "absolute", left: 600, top: 1, width: 196 , height: 155,posLeft: -12, posTop: 381    },
            { id: 1, image: z1el2, klasa: "k2", position: "absolute", left: 350, top: 1, width: 201 , height: 192, posLeft: -14, posTop: 285 },
            { id: 2, image: z1el3, klasa: "k3", position: "absolute", left: 600, top: 197, width: 209 , height: 176, posLeft: -15, posTop: 253  },
            { id: 3,image: z1el4, klasa: "k4", position: "absolute", left: 350, top: 240, width: 209 , height: 90, posLeft: -18, posTop: 193},
            { id: 4,image: z1el5, klasa: "k5", position: "absolute", left: 350, top: 422, width: 210 , height: 101, posLeft: -19, posTop: 126 },
            { id: 5,image: z1el6, klasa: "k6", position: "absolute", left: 600, top: 334, width: 224 , height: 65, posLeft: -18, posTop: 80},
            { id: 5,image: z1el7, klasa: "k7", position: "absolute", left: 600, top: 447, width: 224 , height: 65, posLeft: -25, posTop: 46},
          ],
        },
        {
          id: 2,
          aktywne: true,
          status: false,
          elementy: [
            { order: 1, rotate: "rotate(15deg)", left: 1, top: 455, posLeft: 16, posTop: 14 ,width: 226 , height: 127, txt: "Przygotuj potrzebne narzędzia", position: "absolute", back: "#FFFFFF", color: "#0D988C"  },
            { order: 2, rotate: "rotate(-15deg)", left: 528, top: 463,  posLeft: 256, posTop: 14 , width: 226 , height: 127, txt: "Skompletuj beleczki", position: "absolute", back: "#B9D99D", color: "#0D988C" },
            { order: 3, rotate: "rotate(-6deg)", left: 250, top: 579, posLeft: 498, posTop: 14 , width: 226 , height: 127, txt: "Ułóż beleczki w kształt ramki", position: "absolute", back: "#1CA274", color: "white" },
            { order: 4, rotate: "rotate(15deg)",  left: 250, top: 355,posLeft: 16, posTop: 119 , width: 226 , height: 127, txt: "Zbij ramkę gwoździkami", position: "absolute", back: "#FFFFFF", color: "#0D988C" },
            { order: 5, rotate: "rotate(15deg)", left: 250, top: 460, posLeft: 256,  posTop: 119 , width: 226 , height: 127, txt: "Nawlecz drut na ramkę", position: "absolute",  back: "#50D597", color: "#0D988C" },
            { order: 6, rotate: "rotate(-15deg)", left: 500, top: 365,posLeft: 498, posTop: 119, width: 226 , height: 127,  txt: "Napnij drut", position: "absolute",   back: "#0DC366", color: "white" },
            { order: 7, rotate: "rotate(15deg)",  left: 1, top: 355, posLeft: 16, posTop: 226, width: 226 , height: 127, txt: "Połóż węzę na ramce", position: "absolute",  back: "#B9D99D" , color: "#0D988C" },
            { order: 8, rotate: "rotate(-15deg)", left: 500, top: 255, posLeft: 256, posTop: 226, width: 226 , height: 127,  txt:  "Wtop węzę w ramkę", position: "absolute",  back: "#F9DDBF", color: "#0D988C" },
          ],
        },
        {
          id: 3,
          aktywne: false,
          status: false,
          elementy: [
            { id: 0, image: u1,left: 240, top: 648,width: 44 , height: 51, posLeft: 508, posTop: 376 , posWidth: 453, posHeight: 173 },
            { id: 1, image: u2, left: 340, top: 648, width: 44 , height: 51, posLeft: 508, posTop: 376 , posWidth: 453, posHeight: 173},
            { id: 2, image: u3, left: 440, top: 648, width: 44 , height: 51, posLeft: 508, posTop: 376 , posWidth: 453, posHeight: 173},
            { id: 3,image: u4, left: 540, top: 648, width: 44 , height: 51, posLeft: 508, posTop: 376 , posWidth: 453, posHeight: 173 }
          ],
        },
        {
          id: 4,
          aktywne: false,
          status: false,
          elementy: [
            { id:1, image: z4el1 },
            { id:2, image: z4el2 },
            { id:3, image: z4el3 },
            { id:4, image: z4el4 },
            { id:5, image: z4el5 },
            { id:6, image: z4el6 },
            { id:7, image: z4el7 },
            { id:8, image: z4el8 },
            { id:9, image: z4el9 },
            { id:10, image: z4el10 },
            { id:11, image: z4el11 },
            { id:12, image: z4el12 },
          ],
        },
        {
          id: 5,
          aktywne: false,
          status: false,
          elementy: [
            { id: 0, image: z51, klasa: "z51", position: "absolute", left: 20, top: 2, width: 205 , height: 205, posLeft: 271, posTop: 195    },
            { id: 1, image: z52, klasa: "z52", position: "absolute", left: 600, top: -20, width: 205 , height: 205, posLeft: 2, posTop: 256 },
            { id: 2, image: z53, klasa: "z53", position: "absolute", left: 236, top: 432, width: 205 , height: 205, posLeft: 600, posTop: 250  },
            { id: 3,image: z54, klasa: "z54", position: "absolute", left: 537, top: 489, width: 205 , height: 205, posLeft: 357, posTop: -50},
          ],
        },
        {
          id: 6,
          aktywne: false,
          status: false,
        },
        {
          id: 7,
          aktywne: false,
          status: false,
        },
        {
          id: 8,
          aktywne: false,
          status: false,
          elementy: [
            { id: 0, image: pszczola112, klasa: "z10", position: "absolute", title: "Aster", left: 72, top: 33, width: 196 , height: 155, posLeft: 675, posTop: 28    },
            
          ],
        },
        {
          id: 9,
          aktywne: false,
          status: false,
        },
        {
          id: 10,
          aktywne: false,
          status: false,
          elementy: [
            { id: 0, image: z10, klasa: "z10", position: "absolute", title: "Aster", left: 300, top: 250, width: 196 , height: 155, posLeft: 1, posTop: -47    },
            { id: 1, image: z11, klasa: "z11", position: "absolute", title: "Jeżówka",left: 450, top: 250, width: 201 , height: 192, posLeft: 129, posTop: -53 },
            { id: 2, image: z12, klasa: "z12", position: "absolute", title: "Lawenda", left: 150, top: 250, width: 209 , height: 176, posLeft: 279, posTop: -41  },
            { id: 3,image: z13, klasa: "z13", position: "absolute", title: "Mak", left: 600, top: 250, width: 209 , height: 90, posLeft: 411, posTop: -40},
            { id: 4,image: z14, klasa: "z14", position: "absolute",title: "Wrzos", left: 1, top: 250, width: 209 , height: 90, posLeft: 572, posTop: -19},
          ],
        },
        {
          id: 11,
          aktywne: false,
          status: false,
          elementy: [
            { id: 0, image: pszczola, klasa: "z10", position: "absolute", title: "Aster", left: 1, top: 1, width: 196 , height: 155, posLeft: 735, posTop: 398    },
            { id: 1, image: pszczola, klasa: "z11", position: "absolute", title: "Jeżówka",left: 30, top: 200, width: 201 , height: 192, posLeft: 733, posTop: -4 },
            { id: 2, image: pszczola, klasa: "z12", position: "absolute", title: "Lawenda", left: 1, top: 400, width: 209 , height: 176, posLeft: 712, posTop: 202  },
       
          ],
        },
        {
          id: 12,
          aktywne: false,
          status: false,
        },
        {
          id: 13,
          aktywne: false,
          status: false,
        },
        {
          id: 14,
          aktywne: false,
          status: false,
        },
        {
          id: 15,
          aktywne: false,
          status: false,
        },
        {
          id:16,
          aktywne: false,
          status: false,
          elementy: [
            { id: 0, image: z10, klasa: "z10", position: "absolute", title: "Styczeń", left: 518, top:-350, width: 100 , height: 60, posLeft: 518, posTop: -350, sound: sty  },
            { id: 1, image: z11, klasa: "z11", position: "absolute", title: "Luty",left:1, top: 50, width: 100 , height: 60, posLeft: 587, posTop: -287, sound: lu },
            { id: 2, image: z12, klasa: "z12", position: "absolute", title: "Marzec", left: 400, top:1, width: 100 , height: 60, posLeft: 578, posTop: -215, sound: marz  },
            { id: 3,image: z13, klasa: "z13", position: "absolute", title: "Kwiecień", left: 510, top: -155, width: 100 , height: 60, posLeft: 510, posTop: -155, sound: kw},
            { id: 4,image: z14, klasa: "z14", position: "absolute",title: "Maj", left: 423, top: -125, width: 100 , height: 60, posLeft: 423, posTop: -125, sound: maj},
            { id: 5,image: z14, klasa: "z14", position: "absolute",title: "Czerwiec", left: 400, top: 50, width: 100 , height: 60, posLeft: 309, posTop: -127, sound: cze},
            { id: 6,image: z14, klasa: "z14", position: "absolute",title: "Lipiec", left: 200, top: 150, width: 100 , height: 60, posLeft: 225, posTop: -155 ,sound: lip},
            { id: 7,image: z14, klasa: "z14", position: "absolute",title: "Sierpień", left: 200, top: 100, width: 100 , height: 60, posLeft: 162, posTop: -211 , sound: sier},
            { id: 8,image: z14, klasa: "z14", position: "absolute",title: "Wrzesień", left: 163, top: -284, width: 100 , height: 60, posLeft: 163, posTop: -284, sound: wrz},
            { id: 9,image: z14, klasa: "z14", position: "absolute",title: "Październik", left: 200, top:1, width: 100 , height: 60, posLeft: 206, posTop: -341 ,sound: paz},
            { id: 10,image: z14, klasa: "z14", position: "absolute",title: "Listopad", left:1, top: 150, width: 100 , height: 60, posLeft: 306, posTop: -385, sound: lis},
            { id: 11,image: z14, klasa: "z14", position: "absolute",title: "Grudzień", left:1, top: 100, width: 100 , height: 60, posLeft: 410, posTop: -382, sound: gru},
          ],
        },
        {
          id: 17,
          aktywne: false,
          status: false,
        },
        {
          id: 18,
          aktywne: false,
          status: false,
          elementy: [
            { id: 0, image: deszcz, klasa: "z10", desc:"DESZCZ" , position: "absolute", left: 1, top: 60, width: 196 , height: 155, posLeft: 689, posTop: 465, posLeft2: 371, posLeft3: 531      },
            { id: 1, image: kwiaty, klasa: "z11", desc:"ROŚLINY" , position: "absolute",left: 250, top: 150, width: 201 , height: 192, posLeft: 172, posTop: 465, posLeft2: 11 },
            { id: 2, image: roztocza, klasa: "z12", desc:"ROZTOCZA" , position: "absolute", left: 450, top: 200, width: 209 , height: 176, posLeft: 371, posTop: 465, posLeft2: 531, posLeft3: 689  },
            { id: 3,image: susza, klasa: "z13", desc:"SUSZA" , position: "absolute", left: 677, top: 162, width: 209 , height: 90, posLeft: 531, posTop: 465, posLeft2: 689, posLeft3: 371},
            { id: 4,image: ul, klasa: "z14", desc:"DOBRY UL" , position: "absolute", left: 500, top: 20, width: 209 , height: 90, posLeft: 11, posTop: 465, posLeft2: 172},
          ],
        },
        {
          id: 19,
          aktywne: false,
          status: false,
        },
        {
          id: 20,
          aktywne: false,
          status: false,
        },
        {
          id: 21,
          aktywne: false,
          status: false,
        },
        {
          id: 22,
          aktywne: false,
          status: false,
        },
        {
          id: 23,
          aktywne: false,
          status: false,
        },
        {
          id: 24,
          aktywne: false,
          status: false,
        },
        {
          id: 25,
          aktywne: false,
          status: false,
        },
        {
          id: 26,
          aktywne: false,
          status: false,
          elementy: [   
            { id: 0, image: motyl, klasa: "z51", position: "absolute", left: 20, top: 200, width: 196 , height: 155, posLeft: 16, posTop: 400    },
            { id: 1, image: muchowka, klasa: "z52", position: "absolute", left: 384, top: -5, width: 201 , height: 192, posLeft: 700, posTop: 20 },
            { id: 2, image: osa, klasa: "z53", position: "absolute", left: 407, top: 359, width: 209 , height: 176, posLeft: 1, posTop: 1  },
            { id: 3,image: szerszen, klasa: "z54", position: "absolute", left:200, top: 487, width: 209 , height: 90, posLeft: 600, posTop: 460},
            { id: 4,image: trzmiel, klasa: "z54", position: "absolute", left: 596, top: 251, width: 209 , height: 90, posLeft: 253, posTop: 186},
          ],
        },
      ],
      zamkniete_sekcje: [],
    };

 
   

const gameReducer = (state = initialState, { type, payload, info }) => {
  switch (type) {
    case ADD_MEDAL:
      state.medale.push(payload);
      return { ...state, medale: state.medale, zamkniete_sekcje: info };
    case ADD_ELEMENT:
      state.elementy.push({ image: payload, position: "static", left: null, top: null });
      return { ...state, elementy: state.elementy };

    case ZAKTUALIZUJ_ELEMENTY:
      return { ...state, elementy: payload };

    case ZAKUTALIZUJ_ROZWIAZANIE:
      state.rozwiazane_zadania[info - 1].elementy = payload;
      return { ...state, rozwiazane_zadania: state.rozwiazane_zadania };

    case SET_TARCZA:
      return { ...state, tarcza: payload };

    case SHOW_MODAL:
      return { ...state, modal: payload };

    case HIDE_MODAL:
      return { ...state, modal: null };

    case ZALICZ_ZADANIE:
      return {
        ...state,
        rozwiazane_zadania: payload,
      };
    default:
      return state;
  }
};

export default gameReducer;
