import React, { useContext, useEffect, useState } from "react";
import { Context } from "context";
import labirynt from "assets/s2/z8/labirynth2.svg";
import { connect } from "react-redux";

import { zaktualizujRozwiazanie as zaktualizujRozwiazanieAction } from "actions/gameActions";


import { useDrag } from "react-dnd";

import update from "immutability-helper";

import { useDrop } from "react-dnd";

export const Box = ({ id, left, top, position, children }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: "ItemTypes.BOX" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isDragging) {
    return <div ref={drag}  />;
  }

  return (
    <div className="cards" ref={drag} style={{ left, top, position }}>
      {children}
    </div>
  );
};


const Z8 = ({ elementy, zaktualizujElementy,  zaktualizujRozwiazanie }) => {
  const { canGo, setCanGo } = useContext(Context);

  const [pos, setPos] = useState()

  useEffect(() => {
    setCanGo(true);
  }, []);


  useEffect(() => {
    // const first = elementy[0]
    let count = 0;
    elementy.map( (first, index) => {
    const x1 = first.left + 0.5 * first.width
    const y1 = first.top + 0.5 * first.height
  
    const x2 = first.posLeft + 0.5 * first.width
    const y2 = first.posTop + 0.5 * first.height
  
    if (Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)) < 150){
      count += 1;
      setPos(count) 
      first.left = first.posLeft
      first.top = first.posTop
    }else {
      setPos(count)
    }
  
  
    // console.log(y2-y1)
    // console.log(x2-x1)
  // console.log(Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)))
  })
  
  if(pos === 1) {
    setCanGo(true)
  }else {
    setCanGo(false)
  }
  
  }, [elementy, pos, setCanGo])




  const [collectedProps, drop] = useDrop({
    accept: "ItemTypes.BOX",
    drop(item, monitor) {
      const start_pos = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      // console.log(start_pos, delta);
      const left = item.left ? Math.round(item.left + delta.x) : start_pos.x + delta.x - 200;
      const top = item.top ? Math.round(item.top + delta.y) : start_pos.y + delta.y - 250;

      moveBox(item.id, left, top);
      return undefined;
    },
  });
  const moveBox = (id, left, top) => {
    var el = update(elementy, {
      [id]: {
        $merge: { left, top, position: "absolute" },
      },
    });


    zaktualizujRozwiazanie(el, 8);
   
  };

  return (
    <div className="z z8" ref={drop}>
      <img src={labirynt} alt="labirynt" />
      {pos === 1 && ( 
        <svg
        className="path-svg"
          xmlns="http://www.w3.org/2000/svg"
          width="490.17"
          height="296.207"
          viewBox="0 0 490.17 296.207"
        >
          <path
            d="M3777.5,1569.17v47.609s-4.925,25.993-25.993,26.814-190.161,2.463-190.161,2.463-23.257,4.545-23.257,21.889-1.642,119.842-1.642,119.842,3.783,30.371,23.531,30.371,95.161-1.971,95.161-1.971,17.841-4.587,17.841-41.534V1559.694s.112-27.744-33.846-27.744h-346.8"
            transform="translate(-3292.329 -1526.95)"
            fill="none"
            stroke="#F26464"
            stroke-width="10"
          />
        </svg>
      )}

      <div >
        {Object.keys(elementy).map((key) => {
          const { left, top, position, image, klasa } = elementy[key];
          // console.log(key);      
          return (          
            <Box  key={key} id={key} left={left} top={top} >
              <img  className={klasa} src={image} alt=""  />
            </Box>
          );
        })}
      </div>
    </div>
  );
};




Z8.propTypes = {};

const mapStateToProps = (state) => {
  // console.log(state.gameReducer);
  return {
    elementy: state.gameReducer.rozwiazane_zadania[7].elementy,
    rozwiazane_zadanie: state.gameReducer.rozwiazane_zadania[7],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaktualizujRozwiazanie: (elementy, zadanieID) => dispatch(zaktualizujRozwiazanieAction(elementy, zadanieID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Z8);