import React from "react";
import DashboardTemplate from "templates/DashboardTemplate";
//import { DashboardContext } from "contexts/DashboardContext";
import { Route } from "react-router-dom";
import routes from "routes/routes";
//import RouterBreadcrumbs from "components/molecules/RouterBreadcrumbs/RouterBreadcrumbs";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import logo from "../../assets/logo.svg";
import "../../index.scss"
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import mapa from "assets/mapa_folwarku.png"

const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  min-height: 100vh;
`;

const Dashboard = () => {
  // const [isSidebarHide, setIsSidebarHide] = useState(false);
  /* eslint-disable */
  const small = useMediaQuery({ query: "(max-width: 1100px)" });
  //FIXME:  https://medium.com/@mattywilliams/generating-an-automatic-breadcrumb-in-react-router-fed01af1fc3
  return (
    //   <DashboardContext.Provider value={{ isSidebarHide, setIsSidebarHide }}>
    <StyledWrapper>
      <DashboardTemplate>
        {routes.map(({ path, Component, roles }, key) => {
          // console.log(roles);

          //DODAĆ DOSTĘP DO ŚCIEŻEK!

          // console.log(me);
          // if (roles && !(me.user_role && roles.some((r) => me.user_role.includes(r)))) {
          //   return <p>Test</p>;
          // }
          return (
            <Route
              exact
              path={path}
              key={key}
              render={(props) => {
                const crumbs = routes
                  .filter(({ path }) => props.match.path.includes(path))
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length ? Object.keys(props.match.params).reduce((path, param) => path.replace(`:${param}`, props.match.params[param]), path) : path,
                    ...rest,
                  }));

                return (
                  (small ?  
                    <div className="zaslepka">
                      <a href="https://www.folwarkwawer.pl/" >
                        <img className="logo" src={logo} alt="Folwark Wawer" />
                      </a>
                      <h3>Bardzo nam przykro, ale do zagrania w grę Folwark potrzebna jest myszka. Uruchom stronę na komputerze.</h3>
                      <h2>Miłej zabawy!</h2>
                     
                      <a href="https://www.folwarkwawer.pl/" >
                      <img src={mapa} alt="mapa" />
                      </a>
                    </div>
                    :
                    <>
                    <header className="App-header">
                      <a href="https://www.folwarkwawer.pl/" target="_blank">
                        <img className="logo" src={logo} alt="Folwark Wawer" />
                      </a>
                    <div>
               
                    </div>
                    </header>
                    <DndProvider backend={HTML5Backend}>
                      <Component {...props} />
                    </DndProvider>

                    {/* <RouterBreadcrumbs crumbs={crumbs} /> */}
                  </>
                  )
                
                );
              }}
            />
          );
        })}
      </DashboardTemplate>
    </StyledWrapper>
    //  </DashboardContext.Provider>
  );
  /* eslint-enable */
};

// export default Dashboard;

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

const mapStateToProps = (state) => ({});

// const mapDispatchToProps = () => {};

export default connect(mapStateToProps)(Dashboard);
