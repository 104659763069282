//import { fetchSuccess, } from "actions/appActions";
import { addMedal, addElement, setTarcza, zaliczoneZadanie, showModal } from "actions/gameActions";
//import { checkStatusCode } from "utils/checkStatusCode";

import store from "store";
import { sekcje } from "data/sekcje";
import { zadania } from "data/zadania";

export const zaliczoneZadanieService = (id) => (dispatch) => {
  // console.log(store(true));
  const state = store(true).getState();
  const { rozwiazane_zadania, zamkniete_sekcje } = state.gameReducer;

  // console.log(state);

  // console.log(rozwiazane_zadania);

  let cur_zadanie = id - 1;

  var otwarcie = [];
  var otwarcie_id = 0;
  var suma = 0;
  var i = 0;
  for (i = 0; i < rozwiazane_zadania.length; i++) {
    if (rozwiazane_zadania[i] && rozwiazane_zadania[i].status) {
      suma++;
    }

    if (zadania[i].otwarcie.length && suma >= i) {
      otwarcie = zadania[i].otwarcie;
      otwarcie_id = i;
    }
  }

  // console.log(otwarcie);

  if (otwarcie_id <= suma) {
    otwarcie.forEach((oid) => {
      if (!rozwiazane_zadania[oid - 1]) {
        rozwiazane_zadania[oid - 1] = { id: zadania[oid - 1].id, aktywne: true, status: false };
      } else {
        let rozw = rozwiazane_zadania[oid - 1];
        rozwiazane_zadania[oid - 1] = { ...rozw, aktywne: true };
      }
    });
  }

  if (rozwiazane_zadania[cur_zadanie] && rozwiazane_zadania[cur_zadanie].status === true) {
    suma--;
  } else {
    rozwiazane_zadania[cur_zadanie].status = true;
    dispatch(addElement(zadania[cur_zadanie].element));
  }

  if (zadania[suma].gratulacje && !rozwiazane_zadania[suma].gratulacje_zlozone) {
    rozwiazane_zadania[suma].gratulacje_zlozone = true;
    dispatch(setTarcza(zadania[suma].gratulacje.tarcza));
    dispatch(showModal(zadania[suma].gratulacje));
  }

  sekcje.forEach((sekcja) => {
    if (sekcja.suma === suma + 1 && (!zamkniete_sekcje[sekcja.id - 1] || !zamkniete_sekcje[sekcja.id - 1].zdobyta)) {
      zamkniete_sekcje[sekcja.id - 1] = { id: sekcja.id, zdobyta: true };
      dispatch(addMedal(sekcja.okrzyk.odznaka, zamkniete_sekcje));
      dispatch(showModal(sekcja.okrzyk));
    }
  });

  dispatch(zaliczoneZadanie(rozwiazane_zadania));
};
