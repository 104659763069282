//import React, { useContext } from "react";
// import Navbar from 'components/organisms/Navbar/Navbar';
// import Snackbar from 'components/atoms/Snackbar/Snackbar';
// import Sidebar from 'components/organisms/Sidebar/Sidebar';
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
// import { DashboardContext } from 'contexts/DashboardContext';
import { device } from "theme/mainTheme";

import "./DashboardTemplate.scss";

const StyledWrapper = styled.div`
  margin-left: 0;
  padding: 10rem 0 0;
  height: 100vh;
  position: relative;
  background: #0d988c;
  @media (max-width: 1100px) {
    padding: 5rem 0 0 0 ;
  }
  @media ${device.laptopL} {
    margin-left: 0;
  }
  

  ${({ filterHide }) =>
    filterHide &&
    css`
      margin-right: 15vw;
    `}
`;

const DashboardTemplate = ({ children }) => {
  // const { isSidebarHide } = useContext(DashboardContext);
  return (
    <>
      {/* <Snackbar />
      <Navbar />
      <Sidebar /> */}

      <StyledWrapper /*hide={isSidebarHide}*/>{children}</StyledWrapper>
    </>
  );
};

DashboardTemplate.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default DashboardTemplate;
