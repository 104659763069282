//import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import close from "assets/close.svg";
import { zaktualizujElementy as zaktualizujElementyAction } from "actions/gameActions";
import pasieka from "assets/pasieka/pasieka.svg";

import "./Pasieka.scss";

import { useDrag } from "react-dnd";

import update from "immutability-helper";

import { useDrop } from "react-dnd";

export const Box = ({ id, left, top, position, children }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: "ItemTypes.BOX" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <div ref={drag} style={{ left, top, position }}>
      {children}
    </div>
  );
};

const Pasieka = ({ elementy, zaktualizujElementy }) => {
  const history = useHistory();
  // console.log(elementy);

  // eslint-disable-next-line no-unused-vars
  const [collectedProps, drop] = useDrop({
    accept: "ItemTypes.BOX",
    drop(item, monitor) {
      const start_pos = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      // console.log(start_pos, delta);
      const left = item.left
        ? Math.round(item.left + delta.x)
        : start_pos.x + delta.x - 175;
      const top = item.top
        ? Math.round(item.top + delta.y)
        : start_pos.y + delta.y - 248;

      moveBox(item.id, left, top);
      return undefined;
    },
  });
  const moveBox = (id, left, top) => {
    var el = update(elementy, {
      [id]: {
        $merge: { left, top, position: "absolute" },
      },
    });
    // console.log(el);

    zaktualizujElementy(el);
    // console.log(elementy);
  };

  return (
    <div className="pasieka" ref={drop}>
      <div className="powrot" onClick={(e) => history.push("/pszczoly")}>
        <div>
          <img src={close} alt="" />
        </div>
        <div>
          POWRÓT <br />
          NA TEREN <br />
          PASIEKI
        </div>
      </div>

      <h1 className="pasieka__title">Dokończ budowę swojej pasieki</h1>
      <div className="pasieka__budowa" >
        <img src={pasieka} alt="pasieka" />

        <div className="elementy">
          <div className="elementy__lista">
            {Object.keys(elementy).map((key) => {
              const { left, top, position, image } = elementy[key];
              // console.log(key);
              return (
                <Box
                  key={key}
                  id={key}
                  left={left}
                  top={top}
                  position={position}
                >
                  <img src={image} alt="" />
                </Box>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

Pasieka.propTypes = {};

const mapStateToProps = (state) => {
  return {
    elementy: state.gameReducer.elementy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaktualizujElementy: (elementy) =>
      dispatch(zaktualizujElementyAction(elementy)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pasieka);
