//import React from "react";

import Dashboard from "views/Dashboard/Dashboard";
import Desktop from "views/Dashboard/Desktop/Desktop";
import Pszczoly from "views/Dashboard/Pszczoly/Pszczoly";

import Zadanie from "views/Dashboard/Pszczoly/Zadanie/Zadanie";
import Pasieka from "views/Dashboard/Pszczoly/Pasieka/Pasieka";
import Instructions from "views/Dashboard/Instructions/Instructions";

let routy = [
  { path: "/", name: "", Component: Dashboard, route: "root" },
  {
    path: "/dashboard",
    name: "dashboard",
    Component: Desktop,
  },
  {
    path: "/dashboard/instruction",
    name: "instruckje",
    Component: Instructions,
  },
  {
    path: "/pszczoly",
    name: "pszczoly",
    Component: Pszczoly,
  },
  {
    path: "/pszczoly/moja-pasieka",
    name: "pasieka",
    Component: Pasieka,
  },
  {
    path: "/pszczoly/zadanie/:id",
    name: "zadanie",
    Component: Zadanie,
  },
];

export default routy;
