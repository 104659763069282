import React, { useState, useCallback, useEffect, useContext } from "react";
import { connect } from "react-redux";
import ul1 from "assets/s3/z11/u1.svg";
import ul2 from "assets/s3/z11/u2.svg";
import ul3 from "assets/s3/z11/u3.svg";

import s1 from "assets/s3/z11/s1.svg";
import s2 from "assets/s3/z11/s2.svg";
import s3 from "assets/s3/z11/s3.svg";

import { Context } from "context";

import { zaktualizujElementy as zaktualizujElementyAction } from "actions/gameActions";

import { zaktualizujRozwiazanie as zaktualizujRozwiazanieAction } from "actions/gameActions";

import { useDrag } from "react-dnd";

import update from "immutability-helper";

import { useDrop } from "react-dnd";

export const Box = ({ id, left, top, position, children }) => {
  
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: "ItemTypes.BOX" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isDragging) {
    return <div className="ss" ref={drag} />;
  }

  return (
    <div className="cards" ref={drag} style={{ left, top, position }}>
      {children}
    </div>
  );
};

const Z11 = ({ elementy, zaktualizujElementy, zaktualizujRozwiazanie }) => {
  const { canGo, setCanGo } = useContext(Context);

  const [whichElement, setWhichElement] = useState()

  const [whichColor, setWhichColor] = useState("white")
  const [whichColor2, setWhichColor2] = useState("white")
  const [whichColor3, setWhichColor3] = useState("white")

  const [pos, setPos] = useState()

  useEffect(() => {
    setCanGo(true);
  }, []);



  useEffect(() => {
    // const first = elementy[0]
    let count = 0;
    elementy.map( (first, index) => {
    const x1 = first.left + 0.5 * first.width
    const y1 = first.top + 0.5 * first.height
  
    const x2 = first.posLeft + 0.5 * first.width
    const y2 = first.posTop + 0.5 * first.height
  
    if (Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)) < 80){
      count += 1;
      setPos(count) 
      first.left = first.posLeft
      first.top = first.posTop
      setWhichElement(5)
    }else {
      setPos(count)
      setWhichElement(5)
    }
  
  
    // console.log(y2-y1)
    // console.log(x2-x1)
  // console.log(Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)))
  })
  
  if(pos === 3) {
    setCanGo(true)
  }else {
    setCanGo(false)
  }
  
  }, [elementy, pos, setCanGo])




  useEffect(() => {
    if(whichElement === 0) {
      setWhichColor("#F26464")
      setWhichColor2("white")
      setWhichColor3("white")
    }else if (whichElement === 1) {
      setWhichColor2("#F26464")
      setWhichColor("white")
      setWhichColor3("white")
    }else if(whichElement === 2) {
      setWhichColor3("#F26464")
      setWhichColor("white")
      setWhichColor2("white")
    }
    else {
      setWhichColor("white")
      setWhichColor2("white")
      setWhichColor3("white")
    }

    
  }, [whichElement])

  

  const [collectedProps, drop] = useDrop({
    accept: "ItemTypes.BOX",
    drop(item, monitor) {
      const start_pos = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      // console.log(start_pos, delta);
      const left = item.left
        ? Math.round(item.left + delta.x)
        : start_pos.x + delta.x - 200;
      const top = item.top
        ? Math.round(item.top + delta.y)
        : start_pos.y + delta.y - 250;

      moveBox(item.id, left, top);
      return undefined;
    },
  });
  const moveBox = (id, left, top) => {
    var el = update(elementy, {
      [id]: {
        $merge: { left, top, position: "absolute" },
      },
    });

// setWhichElement(id)
    zaktualizujRozwiazanie(el, 11);
 
  };

  // console.log(whichElement)

  return (
    <div className="z z11" ref={drop}>
      <div className="drop1 drop">
        <img src={ul1} alt="" />
      </div>
      <div className="drop2 drop">
        <img src={ul2} alt="" />
      </div>
      <div className="drop3 drop">
        <img src={ul3} alt="" />
      </div>

      <div>
        {Object.keys(elementy).map((key) => {
          const { left, top, position, image, klasa,id } = elementy[key];
          // console.log(key);
          return (
            <Box key={key} id={key} left={left} top={top} onMouseDown={() => setWhichElement(id)} onMouseUp={() => setWhichElement(5) }>
              <img className={klasa} src={image} alt=""  onMouseDown={() => setWhichElement(id)} onMouseUp={() => setWhichElement(5) } />
            </Box>
          );
        })}
      </div>

      <div className="sciezki">
        {/* <img src={s1} alt="" className="sciezka s1" />
        <img src={s2} alt="" className="sciezka s2" />
        <img src={s3} alt="" className="sciezka s3" /> */}
        <svg 
        className="sciezka s1"
          xmlns="http://www.w3.org/2000/svg"
          width="723.076"
          height="550.188"
          viewBox="0 0 723.076 550.188"
        >
          <path
            id="Path_14126"
            data-name="Path 14126"
            d="M6706.491,11120.214s117.136-63.352,176.464,15.89-31.362,163.865,78.167,225.938,271.884-28.445,290.206-94.48,4.681-107.687-62.6-131.457-296.878,91.634-336.43,136.537c-37.139,42.164-56.121,75.908-56.36,161.539-.015,5.475.048,11.251.191,17.263.056,2.365.136,4.754.251,7.407,4.222,97.7,48.572,228.565,173.289,144.78,127.785-85.846,205.369-195.463,295.122-104.335s104.729,59.835,157.388,33.421"
            transform="translate(-6702.686 -11088.223)"
            fill="none"
            stroke={whichColor}
            strokeWidth="16"
          />
        </svg>

        <svg
        className="sciezka s2"
          xmlns="http://www.w3.org/2000/svg"
          width="690.444"
          height="627.042"
          viewBox="0 0 690.444 627.042"
        >
          <path
            id="Path_14127"
            data-name="Path 14127"
            d="M6755.346,11357.962c103.014,31.7,109.627,39.638,140,108.313s132.07,155.842,196.784,130.749,67.345-121.5,62.063-170.37-36.969-116.222-93.759-142.635-260.351,25.094-273.384-55.469,13.207-161.126,44.9-192.822,245.65-76.6,290.553-13.207,13.207,219.235,84.524,215.273,165.087-128.107,236.4-128.107"
            transform="translate(-6752.994 -10982.432)"
            fill="none"
            stroke={whichColor2}
            strokeWidth="16"
          />
        </svg>

        <svg
        className="sciezka s3"
          xmlns="http://www.w3.org/2000/svg"
          width="663.942"
          height="544.984"
          viewBox="0 0 663.942 544.984"
        >
          <path
            id="Path_14128"
            data-name="Path 14128"
            d="M6699.887,11569.5s250.932-58.32,307.722-104.545S7150.244,11321,7126.471,11251s-136.032-106.977-118.863-147.918,291.874-100.373,313-30.376-62.073,311.685,38.3,269.422"
            transform="translate(-6698.076 -11032.308)"
            fill="none"
            stroke={whichColor3}
            strokeWidth="16"
          />
        </svg>
      </div>
    </div>
  );
};

Z11.propTypes = {};

const mapStateToProps = (state) => {
  // console.log(state.gameReducer);
  return {
    elementy: state.gameReducer.rozwiazane_zadania[10].elementy,
    rozwiazane_zadanie: state.gameReducer.rozwiazane_zadania[10],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaktualizujRozwiazanie: (elementy, zadanieID) =>
      dispatch(zaktualizujRozwiazanieAction(elementy, zadanieID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Z11);
