//import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { zadania } from "data/zadania";
import { sekcje } from "data/sekcje";

import close from "assets/close.svg";
import Zoom from "components/Zoom/Zoom";

import TwojeOdznaki from "components/TwojeOdznaki/TwojeOdznaki";

import { hideModal as hideModalAction } from "actions/gameActions";

import "./Pszczoly.scss";

import mapa_pszczoly from "assets/mapa_pszczoly.png";
import ScrollContainer from "react-indiana-drag-scroll";
// const StyledWrapper = styled(Grid)`
//   && {
//     margin-top: 4rem;
//   }
// `;

const Pszczoly = ({
  zamkniete_sekcje,
  rozwiazane_zadania,
  medale,
  tarcza,
  modal,
  hideModal,
}) => {
  const [width, setWidth] = useState(2500);
  const [height, setHeight] = useState(1400);

  const history = useHistory();

  // console.log(rozwiazane_zadania);
  const small = useMediaQuery({ query: "(max-width: 1430px)" });
  const medium = useMediaQuery({
    query: "(max-width: 1599px) and (min-width: 1430px",
  });
  const Xmedium = useMediaQuery({
    query: "(max-width: 1800px) and (min-width: 1600px",
  });
  const big = useMediaQuery({ query: "(max-width: 2100px)" });
  const Xbig = useMediaQuery({ query: "(max-width: 2800px)" });

  useEffect(() => {
    if (small) {
      setWidth(1280);
      setHeight(720);
    } else if (medium) {
      setWidth(1440);
      setHeight(900);
    } else if (Xmedium) {
      setWidth(1600);
      setHeight(1000);
    } else if (big) {
      setWidth(1920);
      setHeight(1200);
    } else if (Xbig) {
      setWidth(2560);
      setHeight(1440);
    }
  }, [Xbig, Xmedium, big, medium, small]);

  return (
    <>
      <ScrollContainer className="scroll-container" nativeMobileScroll={false}>
        <div className="prisma" style={{ minWidth: width, minHeight: height }}>
          <div className="trawka" style={{ width: width, height: height }}>
            <div
              className="road-container"
              style={{ width: width, height: height }}
            >
              <img className="mapa" src={mapa_pszczoly} alt="" />
              <div className="start__trigger moja_pasieka">
                <p>Moja pasieka</p>
                <button onClick={() => history.push("/pszczoly/moja-pasieka")}>
                  buduj <span className="arrow"></span>
                </button>
              </div>
              {zadania.map((zadanie) => {
                return (
                  <div
                    className={
                      "numerek " +
                      (rozwiazane_zadania[zadanie.id - 1] &&
                      rozwiazane_zadania[zadanie.id - 1].status
                        ? " done "
                        : "") +
                      (rozwiazane_zadania[zadanie.id - 1] &&
                      rozwiazane_zadania[zadanie.id - 1].aktywne
                        ? " aktywne "
                        : "") +
                      " z" +
                      zadanie.id
                    }
                    key={zadanie.id}
                    onClick={(e) =>
                      rozwiazane_zadania[zadanie.id - 1] &&
                      rozwiazane_zadania[zadanie.id - 1].aktywne
                        ? history.push("/pszczoly/zadanie/" + zadanie.id)
                        : ""
                    }
                  >
                    <span>{zadanie.id}</span>
                    <div className="numerek__opis">{zadanie.title}</div>
                  </div>
                );
              })}
              <TwojeOdznaki medale={medale} tarcza={tarcza} />
            </div>
          </div>
          {modal && (
            <div className="modal">
              {modal.tarcza && <img src={modal.tarcza} alt="tarcza" />}
              {modal.odznaka && <img src={modal.odznaka} alt="odznaka" />}
              <h1 className="modal__title">{modal.title}</h1>
              <div className="modal-p">{modal.tekst}</div>
              <button
                className="squared"
                onClick={() => {
                  hideModal();
                }}
              >
                dalej <span className="arrow"></span>
              </button>
            </div>
          )}
          <Zoom
            width={width}
            height={height}
            updateDimensions={(w, h) => {
              setWidth(w);
              setHeight(h);
            }}
          />
          <div className="powrot" onClick={(e) => history.push("/dashboard")}>
            <div>
              <img src={close} alt="" />
            </div>
            <div>
              {" "}
              POWRÓT DO <br />
              FOLWARKU
            </div>
          </div>
        </div>
      </ScrollContainer>
    </>
  );
};

Pszczoly.propTypes = {};

const mapStateToProps = (state) => {
  return {
    zamkniete_sekcje: state.gameReducer.zamkniete_sekcje,
    rozwiazane_zadania: state.gameReducer.rozwiazane_zadania,
    medale: state.gameReducer.medale,
    tarcza: state.gameReducer.tarcza,
    modal: state.gameReducer.modal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => dispatch(hideModalAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pszczoly);
