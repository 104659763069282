import React, { useState, useContext, useEffect } from "react";

import mleczko_pszczele from "assets/s5/z22/mleczko_pszczele.svg";
import kwiaty from "assets/s5/z22/kwiaty.svg";
import miod from "assets/s5/z22/miod.svg";
import pylek from "assets/s5/z22/pylek.svg";
import cx from "classnames";
import { Context } from "context";

const Z22 = () => {
  const [which, setWhich] = useState(0)
   
  const {
    canGo, setCanGo
  } = useContext(Context);




  useEffect(() => {
    setCanGo(false)
  }, [])

  useEffect(() => {
    if(which === 1) {
      setCanGo(true)
    }else {
      setCanGo(false)
    }
  }, [setCanGo, which])



  return (
    <div className="z z22">
      <div className={cx("c1 card", which === 1 ? "active" : "")}
       onClick={() => setWhich(1)} >
        <img src={mleczko_pszczele} alt="" />
        <p>MLECZKO PSZCZELE</p>
      </div>
      <div className={cx("c2 card", which === 2 ? "active" : "")} onClick={() => setWhich(2)}>
        <img src={kwiaty} alt="" />
        <p>KWIATY</p>
      </div>
      <div className={cx("c3 card", which === 3 ? "active" : "")} onClick={() => setWhich(3)}>
        <img src={miod} alt="" />
        <p>MIÓD</p>
      </div>
      <div className={cx("c4 card", which === 4 ? "active" : "")} onClick={() => setWhich(4)}>
        <img src={pylek} alt="" />
        <p>PYŁEK KWIATOWY</p>
      </div>
      <div className="input-box">
      <label>Twoje ulubione danie</label>
      <input className="css-input" type="text" />
      </div>
      
    </div>
  );
};

export default Z22;
