import React,{useEffect, useContext, useState} from "react";
import {CustomRadio } from "react-custom-radio-checkbox";
import { Context } from "context";

const Z14 = () => {


  const {
    canGo, setCanGo
  } = useContext(Context);

   const [rzep, setRzep] = useState()
   const [aka, setAka] = useState()
   const [mni, setMni] = useState()
   const [gry, setGry] = useState()
   const [lipa, setLipa] = useState()


  useEffect(() => {
    setCanGo(false)
  }, [])


useEffect(() => {
  if(rzep === 1 && aka === 3 && mni === 2 && gry === 4 && lipa === 1){
    setCanGo(true)
  } else {
    setCanGo(false)
  }
  
}, [aka, gry, lipa, mni, rzep, setCanGo])

  // console.log(rzep)

  return (
    <div className="z z14">
      <div className="column">
        <h3>Miód rzepakowy</h3>
    
        <CustomRadio label="rzepak" className="check" name="rzep" onChange={(e) =>  setRzep(1)} />
        <CustomRadio label="wrzos" className="check" name="rzep" onChange={(e) => setRzep(2)} />
        <CustomRadio label="mniszek" className="check" name="rzep" onChange={(e) => setRzep(3)} />
        <CustomRadio label="dąb" className="check" name="rzep" onChange={(e) => setRzep(4)} />
     
      </div>

      <div className="column">
        <h3>Miód akacjowy</h3>
        <CustomRadio label="nostrzyk" className="check" name="aka" onChange={(e) =>  setAka(1)} />
        <CustomRadio label="malina" className="check" name="aka" onChange={(e) => setAka(2)} />
        <CustomRadio label="akacja" className="check" name="aka" onChange={(e) => setAka(3)} />
        <CustomRadio label="krokus" className="check" name="aka" onChange={(e) => setAka(4)} />
      </div>

      <div className="column">
        <h3>Miód mniszkowy</h3>
        <CustomRadio label="wrzos" className="check" name="mni" onChange={(e) =>  setMni(1)} />
        <CustomRadio label="mniszek" className="check" name="mni" onChange={(e) => setMni(2)} />
        <CustomRadio label="rododendron" className="check" name="mni" onChange={(e) => setMni(3)} />
        <CustomRadio label="lipa" className="check" name="mni" onChange={(e) => setMni(4)} />
      </div>

      <div className="column">
        <h3>Miód gryczany</h3>
        <CustomRadio label="nawłoć" className="check" name="gry" onChange={(e) =>  setGry(1)}/>
        <CustomRadio label="kasztanowiec" className="check" name="gry" onChange={(e) => setGry(2)}/>
        <CustomRadio label="rzepak" className="check" name="gry" onChange={(e) => setGry(3)}/>
        <CustomRadio label="gryka" className="check" name="gry" onChange={(e) => setGry(4)}/>
      </div>

      <div className="column">
        <h3>Miód lipowy</h3>
        <CustomRadio label="lipa" className="check" name="lipa" onChange={(e) =>  setLipa(1)} />
        <CustomRadio label="koniczyna" className="check" name="lipa" onChange={(e) => setLipa(2)} />
        <CustomRadio label="eukaliptus" className="check" name="lipa" onChange={(e) => setLipa(3)} />
        <CustomRadio label="bergamotka" className="check" name="lipa" onChange={(e) => setLipa(4)} />  
      </div>

    </div>
  );
};

export default Z14;
