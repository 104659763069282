import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Context } from "context";

import { zaktualizujElementy as zaktualizujElementyAction } from "actions/gameActions";

import { zaktualizujRozwiazanie as zaktualizujRozwiazanieAction } from "actions/gameActions";

import { useDrag } from "react-dnd";

import update from "immutability-helper";

import { useDrop } from "react-dnd";

export const Box = ({ id, left, top, position, children }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: "ItemTypes.BOX" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <div className="cards" ref={drag} style={{ left, top, position }}>
      {children}
    </div>
  );
};

const Z26 = ({ elementy, zaktualizujElementy, zaktualizujRozwiazanie }) => {

  const {
    canGo, setCanGo
  } = useContext(Context);

   const [pos, setPos] = useState()


  useEffect(() => {
    setCanGo(false)
  }, [])


  useEffect(() => {
    // const first = elementy[0]
    let count = 0;
    elementy.map( (first, index) => {
    const x1 = first.left + 0.5 * first.width
    const y1 = first.top + 0.5 * first.height
  
    const x2 = first.posLeft + 0.5 * first.width
    const y2 = first.posTop + 0.5 * first.height
  
    if (Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)) < 50){
      count += 1;
      setPos(count) 
      first.left = first.posLeft
      first.top = first.posTop
    }else {
      setPos(count)
    }
  
  
    // console.log(y2-y1)
    // console.log(x2-x1)
  // console.log(Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)))
  })
  
  if(pos === 5) {
    setCanGo(true)
  }else {
    setCanGo(false)
  }
  
  }, [elementy, pos, setCanGo])


  const [collectedProps, drop] = useDrop({
    accept: "ItemTypes.BOX",
    drop(item, monitor) {
      const start_pos = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      // console.log(start_pos, delta);
      const left = item.left
        ? Math.round(item.left + delta.x)
        : start_pos.x + delta.x - 200;
      const top = item.top
        ? Math.round(item.top + delta.y)
        : start_pos.y + delta.y - 250;

      moveBox(item.id, left, top);
      return undefined;
    },
  });

  const moveBox = (id, left, top) => {
    var el = update(elementy, {
      [id]: {
        $merge: { left, top, position: "absolute" },
      },
    });
    // console.log(el);

    zaktualizujRozwiazanie(el, 26);
    // console.log(elementy);
  };

  return (
    <div className="z z26" ref={drop}>

      <div className="drop drop1">OSA</div>
      <div className="drop drop2">MOTYL</div>
      <div className="drop drop3">TRZMIEL</div>
      <div className="drop drop4">MUCHÓWKA</div>
      <div className="drop drop5">SZERSZEŃ</div>


      <div >
        {Object.keys(elementy).map((key) => {
          const { left, top, position, image, klasa, desc } = elementy[key];
          // console.log(key);      
          return (          
            <Box  key={key} id={key} left={left} top={top}   >
             <img  className={klasa} src={image} alt=""   />
            </Box>
          );
        })}
      </div>
      
      {/* <img src={osa} alt="" className="drag drop1" />
      <img src={szerszen} alt="" className="drag drop2" />
      <img src={trzmiel} alt="" className="drag drop3" />
      <img src={muchowka} alt="" className="drag drop4" />
      <img src={motyl} alt="" className="drag drop5" /> */}
    </div>
  );
};

Z26.propTypes = {};

const mapStateToProps = (state) => {
  // console.log(state.gameReducer);
  return {
    elementy: state.gameReducer.rozwiazane_zadania[25].elementy,
    rozwiazane_zadanie: state.gameReducer.rozwiazane_zadania[25],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaktualizujRozwiazanie: (elementy, zadanieID) => dispatch(zaktualizujRozwiazanieAction(elementy, zadanieID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Z26);
