import React, { useContext, useEffect, useState } from "react";
import { Context } from "context";
import tlok from "assets/s5/z20/tlok.svg";

const Z20 = () => {
  const [robo, setrobo] = useState([]);
  const [tru, settru] = useState([]);
  const [kr, setkr] = useState([]);

  const { canGo, setCanGo } = useContext(Context);

  useEffect(() => {
    setCanGo(false);
  }, []);

  useEffect(() => {
    if (parseInt(robo) === 21 && parseInt(tru) === 8 && parseInt(kr) === 1) {
      setCanGo(true);
    } else {
      setCanGo(false);
    }
  }, [kr, robo, setCanGo, tru]);

  // console.log(parseInt(robo));
  // console.log(tru);
  // console.log(kr);

  return (
    <div className="z z20">
      <img className="pszczolki" src={tlok} alt="" />
      <div className="ramka">
        <div>
          <label>Robotnice</label>
          <input
            className="css-input"
            type="number"
            name="robotnice"
            onChange={(e) => {
              if (e.target.value < 100) {
                setrobo(e.target.value);
              } else if (e.target.value > 100) {
                setrobo(99);
              }
            }}
            value={robo}
          />
        </div>

        <div>
          <label>Trutnie</label>
          <input
            className="css-input"
            type="number"
            name="trutnie"
       
            onChange={(e) => {
              if (e.target.value < 100) {
                settru(e.target.value);
              } else if (e.target.value > 100) {
                settru(99);
              }
            }}
            value={tru}
          />
        </div>

        <div>
          <label>Królowa</label>
          <input
            className="css-input"
            type="number"
            name="krolowa"
            onChange={(e) => {
              if (e.target.value < 100) {
                setkr(e.target.value);
              } else if (e.target.value > 100) {
                setkr(99);
              }
            }}
            value={kr}
          />
        </div>
      </div>
    </div>
  );
};

export default Z20;
