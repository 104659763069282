import React, { useState } from "react";
import logo from "../../../assets/logo.svg";
import "./Instructions.scss";
import Plastry from "assets/plastry.svg";
import Taniec from "assets/infopszcz.svg";
import { useHistory } from "react-router-dom";

function Instructions() {
  const [whichSite, setWhichSite] = useState(0);
  const history = useHistory();

  return (
    <>
      <header className="App-header">
        <a href="https://www.folwarkwawer.pl/" target="_blank" rel="noreferrer">
          <img className="logo" src={logo} alt="Folwark Wawer" />
        </a>
      </header>

      <div className="instructions">
        <div className="instruction-inside">
          <div className="instrukcja__opis">
            {whichSite === 0 && (
              <>
                <h1>Witajcie!</h1>
                <h2>
                  Zapraszamy Was, młodych adeptów pszczelarstwa, do odkrycia
                  jego tajemnic. Podczas naszej gry dowiecie się jakich sprzętów
                  używają pszczelarze, kim jest pszczela królowa oraz jak
                  możecie wspierać pszczoły! Przed Wami przeróżne zadania,
                  łamigłówki a także zadania ruchowe. Jesteście gotowi odkryć
                  fascynujący świat pszczół?
                </h2>
              </>
            )}
            {whichSite === 1 && (
              <ul>
                <li>
                  Gra składa się z 27 zadań o różnym poziomie zaawansowania.
                  Czasami może być potrzebna pomoc rodzica.
                </li>
                <li>Zadania są rozmieszczone na 6 ścieżkach tematycznych.</li>
                <li>
                  Do zadań możesz potrzebować kredek, trochę miejsca wokół
                  siebie oraz spostrzegawczości i dokładności.
                </li>
                <li>
                  Po rozwiązaniu zadania kliknik w przycisk "Zakończ zadanie
                </li>
                <li>
                  Za rozwiązanie zadania dpstamoesz elementy do stworzenia
                  swojej pasieki. Możecie do niej przejść klikając w przycisk
                  "Pasieka". Możecie do niej wchodzić w każdym momencie grania.
                </li>
              </ul>
            )}

            {whichSite === 0 && (
              <>
                <div className="instrukcja__html"></div>

                <div className="instrukcja__nav">
                  <div
                    className="wroc"
                    onClick={() => history.push("/dashboard/")}
                  >
                    <span className="arrow arrow-left"></span> wróć
                  </div>
                  <button
                    className="dalej squared"
                    onClick={() => setWhichSite(1)}
                  >
                    dalej <span className="arrow"></span>
                  </button>
                </div>
              </>
            )}
               {whichSite === 1 && (
              <>
                <div className="instrukcja__html"></div>

                <div className="instrukcja__nav">
                  <div
                    className="wroc"
                    onClick={() => setWhichSite(0)}
                  >
                    <span className="arrow arrow-left"></span> wróć
                  </div>
                  <button
                    className="dalej squared"
                    onClick={() => history.push("/pszczoly")}
                    
                  >
                    wchodzę! <span className="arrow"></span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <img className="plastry" src={Plastry} alt="plastry" />
        <img className="taniec" src={Taniec} alt="taniec" />
      </div>
    </>
  );
}

export default Instructions;
