import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "reducers";
import { composeWithDevTools } from "redux-devtools-extension";

import stale1 from "assets/pasieka/czlowiek1.svg";
import stale2 from "assets/pasieka/czlowiek2.svg";
import stale3 from "assets/pasieka/czlowiek3.svg";
import stale4 from "assets/pasieka/drzewa_3.svg";
import stale5 from "assets/pasieka/drzewo_1.svg";
import stale6 from "assets/pasieka/drzewo_2.svg";
import stale7 from "assets/pasieka/dziecko_1.svg";
import stale8 from "assets/pasieka/dziecko_2.svg";
import stale9 from "assets/pasieka/dzrewa_dwa.svg";
import stale10 from "assets/pasieka/trawa.svg";
import stale11 from "assets/pasieka/kobieta.svg";

const store = (read_saved = false) => {
  var intialState = {
    gameReducer: {
      medale: [],
      elementy: [
        { image: stale1, position: "static", left: null, right: null },
        { image: stale2, position: "static", left: null, right: null },
        { image: stale3, position: "static", left: null, right: null },
        { image: stale4, position: "static", left: null, right: null },
        { image: stale5, position: "static", left: null, right: null },
        { image: stale6, position: "static", left: null, right: null },
        { image: stale7, position: "static", left: null, right: null },
        { image: stale8, position: "static", left: null, right: null },
        { image: stale9, position: "static", left: null, right: null },
        { image: stale10, position: "static", left: null, right: null },
        { image: stale11, position: "static", left: null, right: null },
      ],
      tarcza: null,
      rozwiazane_zadania: [
        { id: 1, aktywne: true, status: false },
        { id: 2, aktywne: true, status: false },
      ],
      zamkniete_sekcje: [],
    },
  };

  if (read_saved) {
    try {
      intialState = sessionStorage.getItem("gra_folwark") ? JSON.parse(sessionStorage.getItem("gra_folwark")) : {};
    } catch (error) {
      // console.log("getError", error);
    }
  }

  const saver = (store) => (next) => (action) => {
    let stateToSave = store.getState();
    sessionStorage.setItem("gra_folwark", JSON.stringify({ ...stateToSave }));
    return next(action);
  };

  // console.log(intialState);

  return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, saver)));
};
export default store;

// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

// export default store;
