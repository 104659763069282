export const theme = {
  color: {
    white: "#FFFFFF",
    lightGray: "#F3F3F3",
    gray: "#DEDEDE",
    mediumGray: "#BFBFBF",
    darkGray: "#707070",
    yellow: "#F8B227",
    red: "#E3191B",
    blue: "#076BB3",
    black: "#000000",
    green: "#00BC06",
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },

  fontFamily: {
    head: "'Josefin Sans', sans-serif",
    base: "'Josefin Sans', sans-serif",
  },
};

export const smallFontSize = {
  h1: "8.6rem",
  h2: "5rem",
  h3: "3.8rem",
  h4: "2.6rem",
  h5: "2rem",
  h6: "1.8rem",
  body: "1.4rem",
  body2: "1.2rem",
  caption: "1rem",
  overline: ".8rem",
};

export const bigFontSize = {
  h1: "10.6rem",
  h2: "7rem",
  h3: "5.8rem",
  h4: "3.4rem",
  h5: "2.9rem",
  h6: "2.4rem",
  body: "2rem",
  body2: "1.7rem",
  caption: "1.4rem",
  overline: "1.2rem",
};

export const mediumFontSize = {
  h1: "9.6rem",
  h2: "6rem",
  h3: "4.8rem",
  h4: "3rem",
  h5: "2.4rem",
  h6: "2rem",
  body: "1.6rem",
  body2: "1.4rem",
  caption: "1.2rem",
  overline: "1rem",
};

export const normalColor = {
  white: "#FFFFFF",
  lightGray: "#F3F3F3",
  gray: "#DEDEDE",
  mediumGray: "#BFBFBF",
  darkGray: "#707070",
  yellow: "#F8B227",
  red: "#E3191B",
  blue: "#076BB3",
  black: "#000000",
  green: "#00BC06",
};

export const contrastColor = {
  white: "#000000",
  lightGray: "#444444",
  gray: "#F8B227",
  mediumGray: "#F8B227",
  darkGray: "#F8B227",
  yellow: "#F8B227",
  red: "#F8B227",
  blue: "#F8B227",
  black: "#ffffff", // #000000
  green: "#F8B227",
};

const size = {
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "1920px",
};

export const device = {
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
};
