import React, {useContext, useEffect} from "react";
import { Context } from "context";
import ico_plik from "assets/s3/z13/plik.svg";

const Z13 = () => {

  const {
    canGo, setCanGo
  } = useContext(Context);

  useEffect(() => {
    setCanGo(true)
  }, [])

  return (
    <div className="z z13">
      <div className="plusik">
        <img src={ico_plik} alt="" />
        <h3>Przynieś projekt do Folwarku</h3>
      </div>
    </div>
  );
};

export default Z13;
