import { ADD_MEDAL, ADD_ELEMENT, ZAKTUALIZUJ_ELEMENTY, ZALICZ_ZADANIE, SHOW_MODAL, HIDE_MODAL, SET_TARCZA, ZAKUTALIZUJ_ROZWIAZANIE } from "constants/actionTypes";

export function zaktualizujElementy(payload) {
  return { type: ZAKTUALIZUJ_ELEMENTY, payload };
}

export function addMedal(payload, sekcje) {
  return { type: ADD_MEDAL, payload, info: sekcje };
}

export function addElement(payload) {
  return { type: ADD_ELEMENT, payload };
}

export function setTarcza(payload) {
  return { type: SET_TARCZA, payload };
}

export function zaliczoneZadanie(payload) {
  return { type: ZALICZ_ZADANIE, payload };
}

export function zaktualizujRozwiazanie(payload, zadanieID) {
  return { type: ZAKUTALIZUJ_ROZWIAZANIE, payload, info: zadanieID };
}

export function showModal(payload) {
  return { type: SHOW_MODAL, payload };
}

export function hideModal() {
  return { type: HIDE_MODAL };
}

// export function fetchUserAction(payload) {
//   return { type: FETCH_USER, payload };
// }

// export function fetchMeAction(payload) {
//   return { type: FETCH_ME, payload };
// }

// export function clearMeAction() {
//   return { type: CLEAR_ME };
// }
