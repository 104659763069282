import React, { useEffect, useState } from "react";
import "./Start.scss";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Zoom from "components/Zoom/Zoom";

import mapa from "../../assets/mapa_folwarku.png";
import ScrollContainer from "react-indiana-drag-scroll";

function Start() {
  const [width, setWidth] = useState(2500);
  const [height, setHeight] = useState(1400);

  const small = useMediaQuery({ query: "(max-width: 1430px)" });
  const medium = useMediaQuery({ query: "(max-width: 1599px) and (min-width: 1430px" });
  const Xmedium = useMediaQuery({ query: "(max-width: 1800px) and (min-width: 1600px" });
  const big = useMediaQuery({ query: "(max-width: 2100px)" });
  const Xbig = useMediaQuery({ query: "(max-width: 2800px)" });

  const history = useHistory();

  useEffect(() => {
    if(small) {
      setWidth(1280)
      setHeight(720)
    }else if(medium) {
      setWidth(1440)
      setHeight(900)
    }else if(Xmedium) {
      setWidth(1600)
      setHeight(1000)
    }
    else if(big) {
      setWidth(1920)
      setHeight(1200)
    }else if(Xbig){
      setWidth(2560)
      setHeight(1440)
    }
  },[Xbig, Xmedium, big, medium, small])

  return (
    <>
    
      <ScrollContainer className="scroll-container" nativeMobileScroll={false}>
      <p className="scroll-paragraph">Najedź kursorem na pasiekę i rozpocznij przygodę !</p>
        <div className="prisma" style={{ minWidth: width, minHeight: height }}>
          <div className="trawka" style={{ width: width, height: height }}>
            <div className="road-container" style={{ width: width, height: height }}>
              <img className="mapa" src={mapa} alt="" />
              <div className="start__trigger pszczoly">
                <p>Pasieka i pracownia pasieczna</p>
                <button onClick={() => history.push("/dashboard/instruction")}>
                  zagraj <span className="arrow"></span>
                </button>
              </div>
              <div className="start__trigger szklarnia">
                <p>Szklarnia i pola uprawne</p>
                <span>Dostępne wkrótce</span>
              </div>
            </div>
          </div>
          <Zoom
            width={width}
            height={height}
            updateDimensions={(w, h) => {
              setWidth(w);
              setHeight(h);
            }}
          />
        </div>

      </ScrollContainer>
    </>
  );
}

export default Start;
