import React /*, { useEffect }*/ from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "theme/GlobalStyle";

import "./MainTemplate.css";
import cx from "classnames";

import { theme /*, smallFontSize, bigFontSize, mediumFontSize, normalColor, contrastColor*/ } from "theme/mainTheme";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import { changeFontSize, changeContrast } from "actions/appActions";

const MainTemplate = ({ children, /*fontSize, setFont, setContrast,*/ contrast }) => {
  // useEffect(() => {
  //   if (!window.localStorage.getItem("font-size")) {
  //     window.localStorage.setItem("font-size", fontSize);
  //   } else {
  //     setFont(parseInt(window.localStorage.getItem("font-size"), 10));
  //   }
  // }, [fontSize, setFont]);

  // useEffect(() => {
  //   if (!window.localStorage.getItem("contrast")) {
  //     window.localStorage.setItem("contrast", contrast);
  //     if (contrast) {
  //       document.body.classList.add("contrast-on");
  //     } else {
  //       document.body.classList.remove("contrast-on");
  //     }
  //   } else {
  //     setContrast(window.localStorage.getItem("contrast") === "true");
  //     if (window.localStorage.getItem("contrast") === "true") {
  //       document.body.classList.add("contrast-on");
  //     } else {
  //       document.body.classList.remove("contrast-on");
  //     }
  //   }
  // }, [contrast, setContrast]);

  // const checkFontSize = () => {
  //   switch (fontSize) {
  //     case 1:
  //       return smallFontSize;
  //     case 2:
  //       return mediumFontSize;
  //     case 3:
  //       return bigFontSize;
  //     default:
  //       return mediumFontSize;
  //   }
  // };

  // const checkContrast = () => {
  //   if (contrast) {
  //     return contrastColor;
  //   }
  //   return normalColor;
  // };

  return (
    <div className={cx("mainCol", contrast ? "active" : "")}>
      <GlobalStyle />
      <ThemeProvider theme={{ ...theme /*color: checkContrast(), fontSize: checkFontSize()*/ }}>{children}</ThemeProvider>
    </div>
  );
};

MainTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MainTemplate);
