//import React, { useState, useEffect } from "react";
// import Heading from "components/atoms/Heading/Heading";
//import { Grid /*, Paper*/ } from "@material-ui/core";
// import DashCard from "components/molecules/DashCard/DashCard";
// import DashSmall from "components/molecules/DashCard/DashSmall";
// import DashCalendar from "components/molecules/DashCard/DashCalendar";
// import example from "img/familybig.svg";
// import examplecont from "img/familybigcont.svg";
// import program from "img/program.svg";
// import programcont from "img/programcont.svg";
// import smallone from "img/smallone.svg";
// import smallonecont from "img/smallonecont.svg";
// import smalltwo from "img/smalltwonew.svg";
// import smalltwocont from "img/smalltwonewcont.svg";
// import smallthree from "img/smallthree.svg";
// import smallthreecont from "img/smallthreecont.svg";
// import styled from "styled-components";
//import FloatingButton from "components/atoms/FloatingButton/FloatingButton";
//import PropTypes from "prop-types";
import { connect } from "react-redux";

import Start from "components/Start/Start";

import "./Desktop.scss";
// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, Calendar } from "@material-ui/pickers";
// import polLocale from "date-fns/locale/pl";
// import { useMediaQuery } from "react-responsive";
// // import cx from "classnames";
// import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
// import SupervisedUserCircleRoundedIcon from "@material-ui/icons/SupervisedUserCircleRounded";
// import PollRoundedIcon from "@material-ui/icons/PollRounded";
// import AccountTreeIcon from "@material-ui/icons/AccountTree";
// import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
// import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";

//  import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// const StyledWrapper = styled(Grid)`
//   && {
//     margin-top: 4rem;
//   }
// `;

const Desktop = () => {
  // const isBig = useMediaQuery({ query: "(max-width: 1750px)" });

  // const [fampic, setFamPic] = useState();
  // const [propic, setProPic] = useState();
  // const [small1, setSmall1] = useState();
  // const [small2, setSmall2] = useState();
  // const [small3, setSmall3] = useState();
  // const [backCont, setBackCont] = useState();
  // const [shadowColor, setShadowColor] = useState();
  // const [hoverColor, setHoverColor] = useState();
  // const [floatCont, setFloatCont] = useState();

  return (
    <>
      <Start></Start>
    </>
  );
};

Desktop.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    // setContrast: (e) => dispatch(changeContrast(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Desktop);
