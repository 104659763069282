import React, { createContext, useState } from "react";
import Sound from "react-sound";

export const Context = createContext({});


const ContextProvider = ({ children }) => {

const [canGo, setCanGo] = useState(false)

  return (
    <Context.Provider
    value={{canGo, setCanGo}}
    >
      {children}
    </Context.Provider>
  )
}

export default ContextProvider;
