//import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { DragSource, useDrop } from "react-dnd";
import { DropTarget } from "react-dnd";
import { Context } from "context";
import { zaktualizujRozwiazanie as zaktualizujRozwiazanieAction } from "actions/gameActions";

import { useDrag } from "react-dnd";

import update from "immutability-helper";


export const Box = ({ id, left, top, position, children, order , background , color, transform }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: "ItemTypes.BOX" },
    
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    
  });
  if (isDragging) {
    return <div  ref={drag}  />;
  }

  return (
    <div className="cards" ref={drag} style={{ left, top, position,transform ,  background, color,  zIndex: 3,  }}>
      {children}
    </div>
  );
};



const Z2 = ({elementy, zaktualizujRozwiazanie}) => {
  const [whichElement, setWhichElement] = useState()


  
  const {
    canGo, setCanGo
  } = useContext(Context);

   const [pos, setPos] = useState()


  useEffect(() => {
    setCanGo(false)
  }, [])


  useEffect(() => {
    // const first = elementy[0]
    let count = 0;
    elementy.map( (first, index) => {
    const x1 = first.left + 0.5 * first.width
    const y1 = first.top + 0.5 * first.height
  
    const x2 = first.posLeft + 0.5 * first.width
    const y2 = first.posTop + 0.5 * first.height
  
    if (Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)) < 60){
      count += 1;
      setPos(count) 
      first.left = first.posLeft
      first.top = first.posTop
    }else {
      setPos(count)
    }
  
  
    // console.log(y2-y1)
    // console.log(x2-x1)
  // console.log(Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)))
  })
  
  if(pos === 8) {
    setCanGo(true)
  }else {
    setCanGo(false)
  }
  
  }, [elementy, pos, setCanGo])


//  console.log(elementy.map(first => first.left +0.5 * first.width));



  const [collectedProps, drop] = useDrop({
    accept: "ItemTypes.BOX",
    drop(item, monitor, collectedProps) {
      const start_pos = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      // console.log(start_pos, delta);
      const left = item.left ? Math.round(item.left + delta.x) : start_pos.x + delta.x - 200;
      const top = item.top ? Math.round(item.top + delta.y) : start_pos.y + delta.y - 250;
      setWhichElement(item.id)
      moveBox(item.id, left, top);

      // return undefined;
    },
  });

  const moveBox = (id, left, top, rotate, ) => {
    // console.log(left, top)

    var el = update(elementy, {
      [id]: {
        $merge: { left, top, rotate },
      },
    });
    zaktualizujRozwiazanie(el, 2);
    
    
  };
 

 
  // const history = useHistory();
  // console.log(Object.keys(elementy).map((key) => elementy[key][0]));
  // // console.log(whichElement)

  // const [dustbins, setDustbins] = useState([
  //   { accepts: ["el1"], lastDroppedItem: null },
  //   { accepts: ["el2"], lastDroppedItem: null },
  //   { accepts: ["el3"], lastDroppedItem: null },
  //   { accepts: ["el4"], lastDroppedItem: null },
  //   { accepts: ["el5"], lastDroppedItem: null },
  //   { accepts: ["el6"], lastDroppedItem: null },
  //   { accepts: ["el7"], lastDroppedItem: null },
  //   { accepts: ["el8"], lastDroppedItem: null },
  // ]);


 
  // const [collectedProps, drop] = useDrop({
  //   accept: "ItemTypes.BOX",
  //   drop(item, monitor) {
  //     const start_pos = monitor.getInitialSourceClientOffset();
  //     const delta = monitor.getDifferenceFromInitialOffset();
  //     console.log(start_pos, delta);
  //     const left = item.left ? Math.round(item.left + delta.x) : start_pos.x + delta.x - 150;
  //     const top = item.top ? Math.round(item.top + delta.y) : start_pos.y + delta.y - 150;

  //     moveBox(item.id, left, top);
  //     return undefined;
  //   },
  // });
  // const moveBox = (id, left, top) => {
  //   var el = update(elementy, {
  //     [id]: {
  //       $merge: { left, top, rotate: "0" },
  //     },
  //   });
  //   console.log(el);

  //   zaktualizujRozwiazanie(el, 2);
  //   console.log(elementy);
  // };
  // console.log(elementy.elementy,zaktualizujRozwiazanie);

  // console.log(dustbins);



 
  


  return (
    <div className="z z2" ref={drop}>
      <div className="drops" >
        {/* {dustbins.map(({ accepts, lastDroppedItem }, index) => {
          return <Dustbin accept={accepts} lastDroppedItem={lastDroppedItem} onDrop={(item) => handleDrop(index, item)} key={index} />;
        })} */}

        <div className="dropme d1">1.</div>
        <div className="dropme d2">2.</div>
        <div className="dropme d3">3.</div>
        <div className="dropme d4">4.</div>
        <div className="dropme d5">5.</div>
        <div className="dropme d6">6.</div>
        <div className="dropme d7">7.</div>
        <div className="dropme d8">8.</div>
      </div>
      <div className="over_dragme">
        {Object.keys(elementy).map((key) => {
          const { left, top, rotate, txt, position, back, color } = elementy[key];
          return (
            <Box   key={key} id={key} left={left} top={top} transform={rotate}   background={back} color={color}>
              <p>{txt}</p>
            </Box>
          );
        })}
      </div>
      {/*
      <h1 className="pasieka__title">Dokończ budowę swojej pasieki</h1>
      <div className="pasieka__budowa" ref={drop}> 
        <img src={pasieka} alt="pasieka" />

        <div className="elementy">
          <div className="elementy__lista">
            {Object.keys(elementy).map((key) => {
              const { left, top, position, image } = elementy[key];
              console.log(key);
              return (
                <Box key={key} id={key} left={left} top={top} position={position}>
                  <img src={image} alt="" />
                </Box>
              );
            })}
          </div>
        </div>
      </div> */}
    </div>
  );
};

Z2.propTypes = {};

const mapStateToProps = (state) => {
  // console.log(state.gameReducer);
  return {
    elementy: state.gameReducer.rozwiazane_zadania[1].elementy,
    rozwiazane_zadanie: state.gameReducer.rozwiazane_zadania[1],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaktualizujRozwiazanie: (elementy, zadanieID) => dispatch(zaktualizujRozwiazanieAction(elementy, zadanieID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Z2);
