import React, { useState } from "react";

import audio from "assets/s6/z25/audio.svg";
import pszczola from "assets/s6/z25/pszczola.svg";
import Sound from "react-sound";
import { Context } from "context";
import pszcz from "assets/pszczoly.mp3";

const Z25 = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className="z z25 z19">
      <Sound
        playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
        url={pszcz}
        volume={80}
      />
      <div className="glosnik">
        <img
          id="to"
          className="glos_img"
          src={audio}
          alt=""
          onClick={() => setIsPlaying(!isPlaying)}
          style={{opacity: isPlaying ? "1" : "0.6"}}
        />
        <h3 onClick={() => setIsPlaying(!isPlaying)}
        style={{opacity: isPlaying ? "1" : "0.6"}}
        >
          Odtwórz nazwy gatunków pszczół
        </h3>

        <img src={pszczola} alt="" className="pszczola p1" />
        <img src={pszczola} alt="" className="pszczola p2" />
        <img src={pszczola} alt="" className="pszczola p3" />
        <img src={pszczola} alt="" className="pszczola p4" />
        <img src={pszczola} alt="" className="pszczola p5" />
      </div>
    </div>
  );
};

export default Z25;
