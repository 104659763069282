//import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Context } from "context";
import { zaktualizujElementy as zaktualizujElementyAction } from "actions/gameActions";

import { zaktualizujRozwiazanie as zaktualizujRozwiazanieAction } from "actions/gameActions";


import { useDrag } from "react-dnd";

import update from "immutability-helper";

import { useDrop } from "react-dnd";

export const Box = ({ id, left, top, position, children }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: "ItemTypes.BOX" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isDragging) {
    return <div ref={drag}  />;
  }

  return (
    <div className="cards" ref={drag} style={{ left, top, position }}>
      {children}
    </div>
  );
};

const Z5 = ({ elementy, zaktualizujElementy,  zaktualizujRozwiazanie }) => {


  const {
    canGo, setCanGo
  } = useContext(Context);

   const [pos, setPos] = useState()


  useEffect(() => {
    setCanGo(false)
  }, [])


  useEffect(() => {
    // const first = elementy[0]
    let count = 0;
    elementy.map( (first, index) => {
    const x1 = first.left + 0.5 * first.width
    const y1 = first.top + 0.5 * first.height
  
    const x2 = first.posLeft + 0.5 * first.width
    const y2 = first.posTop + 0.5 * first.height
  
    if (Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)) < 100){
      count += 1;
      setPos(count) 
      first.left = first.posLeft
      first.top = first.posTop
    }else {
      setPos(count)
    }
  
  
    // console.log(y2-y1)
    // console.log(x2-x1)
  // console.log(Math.sqrt(Math.pow(y2-y1, 2) + Math.pow(x2-x1, 2)))
  })
  
  if(pos === 4) {
    setCanGo(true)
  }else {
    setCanGo(false)
  }
  
  }, [elementy, pos, setCanGo])


  const history = useHistory();
  // console.log(pos);

  const [collectedProps, drop] = useDrop({
    accept: "ItemTypes.BOX",
    drop(item, monitor) {
      const start_pos = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      // console.log(start_pos, delta);
      const left = item.left ? Math.round(item.left + delta.x) : start_pos.x + delta.x - 200;
      const top = item.top ? Math.round(item.top + delta.y) : start_pos.y + delta.y - 250;

      moveBox(item.id, left, top);
      return undefined;
    },
  });
  const moveBox = (id, left, top) => {
    var el = update(elementy, {
      [id]: {
        $merge: { left, top, position: "absolute" },
      },
    });


    zaktualizujRozwiazanie(el, 5);
   
  };

  return (
    <div className="z z5" ref={drop}>
      {/* <div className="drag drag1">
        <img src={kloda_bartna} alt="1" />
      </div>
      <div className="drag drag2">
        <img src={koszka_ze_slomy} alt="2" />
      </div>
      <div className="drag drag3">
        <img src={ul_figuralny} alt="3" />
      </div>
      <div className="drag drag4">
        <img src={ul_snozowy} alt="4" />
      </div> */}
      <div className="drop drop1">Kłoda Bratna</div>
      <div className="drop drop2">Ul Snozowy</div>
      <div className="drop drop3">Ul figuralny</div>
      <div className="drop drop4">Kószka ze Słomy</div>

      <div >
        {Object.keys(elementy).map((key) => {
          const { left, top, position, image, klasa } = elementy[key];
          // console.log(key);      
          return (          
            <Box  key={key} id={key} left={left} top={top} >
              <img  className={klasa} src={image} alt=""  />
            </Box>
          );
        })}
      </div>
      
      {/* 
      <h1 className="pasieka__title">Dokończ budowę swojej pasieki</h1>
      <div className="pasieka__budowa" ref={drop}>
        <img src={pasieka} alt="pasieka" />

        <div className="elementy">
          <div className="elementy__lista">
            {Object.keys(elementy).map((key) => {
              const { left, top, position, image } = elementy[key];
              console.log(key);
              return (
                <Box key={key} id={key} left={left} top={top} position={position}>
                  <img src={image} alt="" />
                </Box>
              );
            })}
          </div>
        </div>
      </div> */}
    </div>
  );
};

Z5.propTypes = {};

const mapStateToProps = (state) => {
  // console.log(state.gameReducer);
  return {
    elementy: state.gameReducer.rozwiazane_zadania[4].elementy,
    rozwiazane_zadanie: state.gameReducer.rozwiazane_zadania[4],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zaktualizujRozwiazanie: (elementy, zadanieID) => dispatch(zaktualizujRozwiazanieAction(elementy, zadanieID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Z5);
