
import aksamitka from "assets/s2/z7/aksamitka.svg";
import czarny_bez from "assets/s2/z7/czarny_bez.svg";
import krwawnik from "assets/s2/z7/krwawnik.svg";
import mniszek from "assets/s2/z7/mniszek.svg";
import piolun from "assets/s2/z7/piolun.svg";
import pokrzywa from "assets/s2/z7/pokrzywa.svg";


const z7data = [
  {
    id: 1,
    title: "Aksamitka",
    img: aksamitka,
    desc: "Rodzaj roślin jednorocznych należących do rodziny astrowatych (Asteraceae). Do aksamitek zaliczamy ponad 40 gatunków! Jako roślina dzika występuje na obu kontynentach Ameryki w strefie klimatu tropikalnego i umiarkowanego (ciepłego)."
  },
  {
    id: 2,
    title: "Czarny Bez",
    img: czarny_bez,
    desc: "Krzew obecnie należący do rodziny piżmaczkowatych (Adoxaceae). Jest często nazywany bzem białym, dzikim, lekarskim, aptekarskim czy pospolitym i w Polsce występuje naturalnie – można go spotkać na nieużytkach, przydrożach czy skrajach lasów."
  },
  {
    id: 3,
    title: "Krwawnik",
    img: krwawnik, 
    desc: "Należą do rodziny astrowatych (Asteraceae). To ciekawe rośliny mające bardzo drobne kwiatki zebrane w płaskie kwiatostany. Na naszych łąkach spotkać można głównie rośliny białe lub bladoróżowe. W ogrodach można uprawiać też niezwykle urokliwe okazy o kwiatach żółtych, ciemnoróżowych, fioletowych, pomarańczowych czy nawet czerwonych."
  },
  {
    id: 4,
    title: "Mniszek",
    img: mniszek,
    desc: "Mniszek lekarski to jedna z najbardziej znanych i powszechnie występujących roślin w Polsce. Jego dobroczynny wpływ na nasze zdrowie został już dawno doceniony i z tego też powodu znalazł szerokie zastosowanie w medycynie ludowej."
  },
  {
    id: 5,
    title: "Piołun",
    img: piolun,
    desc: "W piołunie można znaleźć garbniki, sole mineralne, flawonoidy i olejki eteryczne. Mają one działanie bakteriobójcze, przeciwskurczowe, przeciwzapalne i żółciopędne, a nawet bronią organizm przed pasożytami."
  },
  {
    id: 6,
    title: "Pokrzywa",
    img: pokrzywa,
    desc: "Pokrzywa występuje powszechnie niemal na całej kuli ziemskiej z wyjątkiem strefy tropikalnej. Rośnie dziko i być może dlatego często jest niedoceniana i traktowana jak chwast – wyrywana i wyrzucana. Warto zdać sobie sprawę jak wiele korzyści może przynieść nam jej zastosowanie w codziennym życiu. "
  }
]


export default z7data;