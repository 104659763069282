import React, { useEffect, useState, useContext } from "react";
import { Context } from "context";
import miasto from "assets/s2/z6/miasto.svg";

const Z6 = () => {

  const {
    canGo, setCanGo
  } = useContext(Context);

  const [inputValue, setInputValue] = useState()

  // console.log(inputValue)
  // console.log(canGo)

  useEffect(() => {
    if(inputValue === "13"){
      setCanGo(true)
    }else {
      setCanGo(false)
    }
  
  },[inputValue, setCanGo])

  return (
    <div className="z z6">
      <img src={miasto} alt={miasto} />
      <div className="okienko">
        <label for="liczba">Wpisz wynik w okienko</label>
        <input className="css-input" type="text" name="liczba" id="liczba" autoComplete="off" onChange={(e) => setInputValue(e.target.value)} />
      </div>
    </div>
  );
};

export default Z6;
